import {
  ATT_LOAD_LIST_FAIL,
  ATT_LOAD_LIST_REQUEST,
  ATT_LOAD_LIST_SUCCESS,
  ATT_MODIFY_PRESENCE_FAIL,
  ATT_MODIFY_PRESENCE_REQUEST,
  ATT_MODIFY_PRESENCE_SUCCESS,
} from "../constants/attConstants";
import axiosInstance, { manageReason } from "./axios";
import { signout } from "./userActions";

export const loadAllAttendees = (discipline) => async (dispatch) => {
  dispatch({ type: ATT_LOAD_LIST_REQUEST, payload: { discipline } });
  axiosInstance
    .post("/users/attendee.php", { discipline, type: "getall" })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({ type: ATT_LOAD_LIST_SUCCESS, payload: response.data });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: ATT_LOAD_LIST_FAIL,
          payload: message,
        });
      }
    });
};

export const modifyAttendeesPresence =
  (discipline, when, group, usrid, present) => async (dispatch) => {
    dispatch({
      type: ATT_MODIFY_PRESENCE_REQUEST,
      payload: { discipline, when, group, usrid, present },
    });
    axiosInstance
      .post("/users/attendee.php", {
        discipline,
        when,
        group,
        usrid,
        present,
        type: "modify",
      })
      .then((response) => {
        if (response === undefined) {
          dispatch(signout());
        } else {
          dispatch({
            type: ATT_MODIFY_PRESENCE_SUCCESS,
            payload: response.data,
          });
        }
      })
      .catch((reason) => {
        const { message, exit } = manageReason(reason);
        if (exit) {
          dispatch(signout());
        } else {
          dispatch({
            type: ATT_MODIFY_PRESENCE_FAIL,
            payload: message,
          });
        }
      });
  };
