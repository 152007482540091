import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Alert, Box, Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { signout, unloadProfile } from "../actions/userActions";
import { useDispatch } from "react-redux";
import { CloseOutlined } from "@mui/icons-material";
import { CleanupDialog } from "../components/CleanupDialog";
import { deleteRequest } from "../utils/utils";

export default function DatabaseCleanupScreen() {
  const [messages, setMessages] = useState([]);
  const [openCleanup, setOpenCleanup] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(unloadProfile());
  }, [dispatch]);

  function openCleanupDialog() {
    setOpenCleanup(true);
  }

  async function onCleanup(save, preview, actions) {
    if ((save || preview) && actions) {
      let newMessages = await actions.reduce(async (promise, act) => {
        return promise.then(async (msgs) => {
          if (act.value) {
            const ret = await deleteRequest(act.id, preview);
            if (ret.error) {
              msgs.push({ severity: "error", text: ret.error });
              if (ret.exit) {
                dispatch(signout());
              }
            } else {
              const text = save
                ? `Cancellazione ${act.label} completata (${ret.affected} righe)`
                : `Anteprima ${act.label}: ${ret.affected} righe`;
              msgs.push({
                severity: "info",
                text,
              });

              if (ret.numfiles !== undefined) {
                const textfile = save
                  ? `Cancellazione ${act.label} completata (${ret.numfiles} files)`
                  : `Anteprima ${act.label}: ${ret.numfiles} files`;
                msgs.push({
                  severity: "info",
                  text: textfile,
                });
              }
            }
          }

          return msgs;
        });
      }, Promise.resolve([]));

      setMessages(newMessages);
    }

    setOpenCleanup(false);
  }

  return (
    <>
      {messages.map((m, idx) => (
        <Alert
          severity={m.severity}
          key={idx}
          action={
            <IconButton
              onClick={() =>
                setMessages((old) => {
                  return [...old.slice(0, idx), ...old.slice(idx + 1)];
                })
              }
            >
              <CloseOutlined />
            </IconButton>
          }
        >
          {m.text}
        </Alert>
      ))}
      <CleanupDialog dlgOpen={openCleanup} onConfirm={onCleanup} />
      <Box margin={10} display="flex">
        <WarningAmberIcon sx={{ fontSize: "5rem", color: "orange" }} />
        <Box sx={{ ml: "1rem", color: "orangered", mt: "2rem" }}>
          Questa funzione e' irreversibile, usarla con criterio e dopo aver
          fatto un backup del database
        </Box>
      </Box>
      <Button onClick={() => openCleanupDialog()}>Procedi</Button>
    </>
  );
}
