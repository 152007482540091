import { Box } from "@mui/system";
import React, { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Outlet,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import "./App.css";
import NavigationBar from "./components/NavigationBar";
import CategoriesScreen from "./screens/CategoriesScreen";
import ConfirmMailScreen from "./screens/ConfirmMailScreen";
import DocListScreen from "./screens/DocListScreen";
import DocumentScreen from "./screens/DocumentScreen";
import HomeScreen from "./screens/HomeScreen";
import LogsScreen from "./screens/LogsScreen";
import LostPasswordScreen from "./screens/LostPasswordScreen";
import ParametersScreen from "./screens/ParametersScreen";
import ParentScreen from "./screens/ParentScreen";
import PricelistScreen from "./screens/PricelistScreen";
import ProfileScreen from "./screens/ProfileScreen";
import RegisterScreen from "./screens/RegisterScreen";
import SessionsScreen from "./screens/SessionsScreen";
import SignupScreen from "./screens/SignupScreen";
import TransferScreen from "./screens/TransferScreen";
import UsersScreen from "./screens/UsersScreen";
import { canTransfer } from "./utils/config";
import CookieConsent from "react-cookie-consent";
import AttendeesScreen from "./screens/AttendeesScreen";
import RegisterShortScreen from "./screens/RegisterShortScreen";
import UserAlertsScreen from "./screens/UserAlertsScreen";
import LocationsScreen from "./screens/LocationsScreen";
import SignupModule from "./modules/SignupModule";
import AttendeesSummaryScreen from "./screens/AttendeesSummaryScreen";
import DisciplinesScreen from "./screens/DisciplinesScreen";
import DocModulesScreen from "./screens/DocModulesScreen";
import DiscipineEditScreen from "./screens/DisciplineEditScreen";
import DatabaseCleanupScreen from "./screens/DatabaseCleanup";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

function App() {
  useLayoutEffect(() => {
    document.body.style.backgroundColor =
      process.env.REACT_APP_BGCOLOR || "#EAFFD8";
  });

  return (
    <ThemeProvider theme={theme}>
      <Router basename={"/" + process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<LayoutsWithNavbar />}>
            <Route exact path="/" element={<HomeScreen />} />
            <Route path="*" element={<InProgress />} />
            <Route
              path="/trial/:discipline"
              element={<RegisterShortScreen />}
            />
            <Route path="/signup/:discipline" element={<RegisterScreen />} />
            <Route path="/lostpassword" element={<LostPasswordScreen />} />
            <Route
              path="/confirmmail/:mailtoken"
              element={<ConfirmMailScreen />}
            />
            <Route
              path="/attendees"
              element={
                <RequireAuth trainer>
                  <AttendeesScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/attendeessummary"
              element={
                <RequireAuth admindisc>
                  <AttendeesSummaryScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/useralerts"
              element={
                <RequireAuth manager>
                  <UserAlertsScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/locations"
              element={
                <RequireAuth admindisc>
                  <LocationsScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/users"
              element={
                <RequireAuth manager>
                  <UsersScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/profile/:id"
              element={
                <RequireAuth>
                  <ProfileScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/profile/:id/parents/:parid"
              element={
                <RequireAuth>
                  <ParentScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/profile/:id/payments"
              element={
                <RequireAuth manager>
                  <SignupScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/profile/:id/docs"
              element={
                <RequireAuth>
                  <DocumentScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/profile/:id/docs/:type"
              element={
                <RequireAuth>
                  <DocumentScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/profile/:id/signup"
              element={
                <RequireAuth>
                  <SignupModule />
                </RequireAuth>
              }
            />
            {canTransfer && (
              <Route
                path="/transferusers"
                element={
                  <RequireAuth manager>
                    <TransferScreen />
                  </RequireAuth>
                }
              />
            )}
            <Route
              path="/documents/:docType"
              element={
                <RequireAuth manager>
                  <DocListScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/parameters"
              element={
                <RequireAuth admin>
                  <ParametersScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/categories"
              element={
                <RequireAuth admindisc>
                  <CategoriesScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/modulesedit"
              element={
                <RequireAuth admindisc>
                  <DiscipineEditScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/disciplines"
              element={
                <RequireAuth admin>
                  <DisciplinesScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/pricelist"
              element={
                <RequireAuth admin>
                  <PricelistScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/dbcleanup"
              element={
                <RequireAuth admin>
                  <DatabaseCleanupScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/logs"
              element={
                <RequireAuth admin>
                  <LogsScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/sessions"
              element={
                <RequireAuth admin>
                  <SessionsScreen />
                </RequireAuth>
              }
            />
            <Route
              exact
              path="/modules"
              element={
                <RequireAuth>
                  <DocModulesScreen />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

function LayoutsWithNavbar() {
  return (
    <>
      <CookieConsent buttonText="Accetto">
        Questo sito utilizza i cookie per migliorarne la fruibilità.
      </CookieConsent>
      <NavigationBar />

      {/* This Outlet is the place in which react-router will render your components that you need with the navbar */}
      <Outlet />
    </>
  );
}

function InProgress() {
  return <Box>Pagina in costruzione</Box>;
}

function RequireAuth({
  manager = false,
  admin = false,
  admindisc = false,
  trainer = false,
  children,
}) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate("/", { replace: true, state: { from: location.pathname } });
    }
  }, [userInfo, location, navigate]);

  if (trainer && userInfo && !(userInfo.hasAcl || userInfo.isAdmin)) {
    return <Box>Non hai accesso a questa area</Box>;
  }
  if (manager && userInfo && !(userInfo.hasAcl || userInfo.isAdmin)) {
    return <Box>Non hai accesso a questa area</Box>;
  }
  if (
    admindisc &&
    userInfo &&
    !(userInfo?.mgrDiscipline?.length > 0 || userInfo.isAdmin)
  ) {
    return <Box>Non hai accesso a questa area</Box>;
  }
  if (admin && userInfo && !userInfo.isAdmin) {
    return <Box>Non hai accesso a questa area</Box>;
  }

  return children;
}

export default App;
