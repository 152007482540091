import {
  TRANSFER_IMPORT_FAIL,
  TRANSFER_IMPORT_REQUEST,
  TRANSFER_IMPORT_SUCCESS,
  TRANSFER_LOADUSERS_FAIL,
  TRANSFER_LOADUSERS_REQUEST,
  TRANSFER_LOADUSERS_SUCCESS,
  TRANSFER_SIGNIN_FAIL,
  TRANSFER_SIGNIN_REQUEST,
  TRANSFER_SIGNIN_SUCCESS,
} from "../constants/transferConstants";

export const transferReducer = (
  state = {
    transferUsers: [],
    loading: false,
    error: undefined,
    transferInfo: localStorage.getItem("transferInfo")
      ? JSON.parse(localStorage.getItem("transferInfo"))
      : null,
    messages: [],
  },
  action
) => {
  switch (action.type) {
    case TRANSFER_SIGNIN_REQUEST:
      return { loading: true, error: undefined, messages: [] };
    case TRANSFER_SIGNIN_SUCCESS:
      localStorage.setItem("transferInfo", JSON.stringify(action.payload));
      return { loading: false, error: undefined, transferInfo: action.payload };
    case TRANSFER_SIGNIN_FAIL:
      localStorage.removeItem("transferInfo");
      return {
        ...state,
        transferInfo: null,
        loading: false,
        error: action.payload,
      };
    case TRANSFER_IMPORT_REQUEST:
      return { ...state, loading: true, error: undefined, messages: [] };
    case TRANSFER_LOADUSERS_REQUEST:
      return { ...state, loading: true, error: undefined };
    case TRANSFER_LOADUSERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        transferUsers: action.payload,
      };
    case TRANSFER_LOADUSERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        transferUsers: [],
      };
    case TRANSFER_IMPORT_SUCCESS:
      return {
        ...state,
        messages: action.payload.messages,
        loading: false,
      };
    case TRANSFER_IMPORT_FAIL:
      return {
        ...state,
        messages: action.payload.messages,
        error: action.payload.message,
        loading: false,
      };
    default:
      return state;
  }
};
