import { Alert, Box, Button, FormControl, InputLabel, LinearProgress, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signin } from "../actions/userActions";

export default function LoginScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { loading, error } = userSignin;

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = (event) => {
    event.preventDefault();

    dispatch(signin(userName, password, ''));
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
      >
        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-username">
            Nome utente
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-username"
            type="text"
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
            label="Nome utente"
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            label="Password"
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <Button variant="contained" onClick={submitHandler}>
            Entra
          </Button>
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <Button variant="contained" color="warning" onClick={() => navigate('/lostpassword')}>
            Nome utente e/o Password dimenticati
          </Button>
        </FormControl>
      </Box>
    </>
  );
}
