export const USERS_LOADPROFILES_REQUEST = 'USERS_LOADPROFILES_REQUEST';
export const USERS_LOADPROFILES_SUCCESS = 'USERS_LOADPROFILES_SUCCESS';
export const USERS_LOADPROFILESPARTIAL_SUCCESS = 'USERS_LOADPROFILESPARTIAL_SUCCESS';
export const USERS_LOADPROFILES_FAIL = 'USERS_LOADPROFILES_FAIL';

export const USERS_REQUEST_USERDATA = 'USERS_REQUEST_USERDATA';
export const USERS_FAIL_USERDATA = 'USERS_FAIL_USERDATA';
export const USERS_UPDATE_USERDATA = 'USERS_UPDATE_USERDATA';

export const USERS_DELETE_USER = 'USERS_DELETE_USER';
