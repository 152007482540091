import {
  DOCS_FORCE_RELOAD,
  DOCS_LOAD_GENERIC_FAIL,
  DOCS_LOAD_GENERIC_REQUEST,
  DOCS_LOAD_GENERIC_SUCCESS,
  DOCS_LOAD_WIRETRANSFER_FAIL,
  DOCS_LOAD_WIRETRANSFER_REQUEST,
  DOCS_LOAD_WIRETRANSFER_SUCCESS,
} from "../constants/docConstants";

export const documentReducer = (
  state = { wiretransfers: [], documents: [], error: undefined, loading: false },
  action
) => {
  switch (action.type) {
    case DOCS_FORCE_RELOAD:
      return { wiretransfers: [], documents: [], error: undefined };
    case DOCS_LOAD_GENERIC_REQUEST:
      return { ...state, loading: true };
    case DOCS_LOAD_GENERIC_SUCCESS:
      return { ...state, loading: false, documents: action.payload };
    case DOCS_LOAD_GENERIC_FAIL:
      return { loading: false, error: action.payload };
    case DOCS_LOAD_WIRETRANSFER_REQUEST:
      return { ...state, loading: true };
    case DOCS_LOAD_WIRETRANSFER_SUCCESS:
      return { ...state, loading: false, wiretransfers: action.payload };
    case DOCS_LOAD_WIRETRANSFER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
