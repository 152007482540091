import {
  DOCS_FORCE_RELOAD,
  DOCS_LOAD_GENERIC_FAIL,
  DOCS_LOAD_GENERIC_REQUEST,
  DOCS_LOAD_GENERIC_SUCCESS,
  DOCS_LOAD_WIRETRANSFER_FAIL,
  DOCS_LOAD_WIRETRANSFER_REQUEST,
  DOCS_LOAD_WIRETRANSFER_SUCCESS,
} from "../constants/docConstants";
import axiosInstance, { manageReason } from "./axios";
import { signout } from "./userActions";

export const loadDocuments = () => async (dispatch, getState) => {
  dispatch({ type: DOCS_LOAD_GENERIC_REQUEST, payload: {} });
  axiosInstance
    .get("/users/doclist.php?docType=generic")
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({ type: DOCS_LOAD_GENERIC_SUCCESS, payload: response.data });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: DOCS_LOAD_GENERIC_FAIL,
          payload: message,
        });
      }
    });
};

export const loadWiretransfers = () => async (dispatch) => {
  dispatch({ type: DOCS_LOAD_WIRETRANSFER_REQUEST, payload: {} });
  axiosInstance
    .get("/users/doclist.php?docType=wiretransfer")
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: DOCS_LOAD_WIRETRANSFER_SUCCESS,
          payload: response.data,
        });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: DOCS_LOAD_WIRETRANSFER_FAIL,
          payload: message,
        });
      }
    });
};

// chiamato su upload
export const forceReload = () => async (dispatch) => {
  dispatch({ type: DOCS_FORCE_RELOAD, payload: {} });
};
