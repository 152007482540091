import {
  Alert,
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import {
  loadCategories,
  loadParams,
  updateCategory,
} from "../actions/paramActions";
import { Add, Delete, Edit } from "@mui/icons-material";
import { makeStyles } from '@mui/styles';
import { signupDocTypes } from "../utils/config";
import { loadUsers } from "../actions/usersActions";
import { unloadProfile } from "../actions/userActions";

const useStyles = makeStyles({
  deftable: {
    "& td": { fontSize: "0.9rem", paddingTop: 0, paddingBottom: 0 },
  },
});

export default function CategoriesScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const paramList = useSelector((state) => state.paramList);
  const { disciplines, categories, loading, error } = paramList;
  const usersList = useSelector((state) => state.usersList);
  const { userProfiles } = usersList;

  const [editCategory, setEditCategory] = useState(null);
  const [dlgOpen, setDlgOpen] = useState(false);

  const dispatch = useDispatch();

  const classes = useStyles();

  function tableProps() {
    return {
      className: classes.deftable,
    };
  }

  function handleCategorySave(action, cat) {
    dispatch(updateCategory(action, cat));
  }

  function renderCategory(value, { rowData }, updateValue) {
    const cat = categories.find((c) => c.key === rowData[0]);

    return (
      <Badge
        badgeContent={
          userProfiles?.filter((user) => user.category === cat.key)?.length
        }
        color="primary"
      >
        <Box width="4rem">{value}</Box>
      </Badge>
    );
  }

  function renderCategoryActions(value, { rowData }, updateValue) {
    const cat = categories.find((c) => c.key === rowData[0]);

    return (
      <Stack direction="row" spacing={0.4}>
        <IconButton
          size="small"
          onClick={() => {
            setEditCategory(cat);
            setDlgOpen(true);
          }}
        >
          <Edit />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            handleCategorySave("delete", cat);
          }}
          disabled={
            userProfiles?.filter((user) => user.category === cat.key)?.length >
            0
          }
        >
          <Delete />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            setEditCategory({
              key: "",
              discipline: "",
              description: "",
              isjr: false,
              iscomp: false,
              isinternal: false,
              doctype: 0,
            });
            setDlgOpen(true);
          }}
        >
          <Add />
        </IconButton>
      </Stack>
    );
  }

  function renderCheckbox(value, { rowData }, updateValue) {
    return (
      <>
        <Checkbox checked={value > 0} />
      </>
    );
  }

  const catColumns = [
    {
      name: "key",
      label: "Chiave",
      options: {
        customBodyRender: renderCategory,
      },
    },
    {
      name: "discipline",
      label: "Sport",
    },
    { name: "description", label: "Etichetta" },
    {
      name: "isjr",
      label: process.env.REACT_APP_JRLABEL || "Junior",
      options: { customBodyRender: renderCheckbox },
    },
    {
      name: "iscomp",
      label: process.env.REACT_APP_COMPLABEL || "Competitivo",
      options: { customBodyRender: renderCheckbox },
    },
    {
      name: "isinternal",
      label: "Uso Interno",
      options: { customBodyRender: renderCheckbox },
    },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderCategoryActions },
    },
  ];

  useEffect(() => {
    dispatch(unloadProfile());
    if (!categories?.length) {
      dispatch(loadCategories());
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (!userProfiles?.length) dispatch(loadUsers());
  }, [dispatch, userProfiles]);

  useEffect(() => {
    if (!disciplines?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, disciplines]);

  const EditCategoryDialog = ({ dialogCategory }) => {
    const [cat, setCat] = useState(dialogCategory);

    const handleDlgClose = (save) => {
      if (save) {
        const prefix = disciplines.find(
          (disc) => disc.id === cat.discipline
        )?.prefix;
        const key = cat?.id ? cat.key : `${prefix}_${cat.key}`;
        handleCategorySave("set", { ...cat, key });
      }

      setDlgOpen(false);
    };

    return (
      <Dialog open={dlgOpen} onClose={() => handleDlgClose(false)}>
        <DialogTitle>
          {cat?.id > 0 ? "Modifica " + cat?.key : "Aggiungi"}
        </DialogTitle>
        <DialogContent>
          {!cat?.id && (
            <TextField
              margin="dense"
              id="key"
              label="Chiave"
              type="text"
              variant="standard"
              fullWidth
              value={cat?.key}
              disabled={cat?.id > 0}
              onChange={(event) => setCat({ ...cat, key: event.target.value })}
            />
          )}
          <FormControl fullWidth sx={{ mt: 1 }} variant="standard">
            <InputLabel id="discipline">Sport</InputLabel>
            <Select
              id="discipline"
              label="Sport"
              value={cat?.discipline}
              fullWidth
              disabled={cat?.id >0}
              onChange={(event) => {
                setCat({ ...cat, discipline: event.target.value });
              }}
            >
              {disciplines
                ?.filter(
                  (disc) =>
                    userInfo?.mgrDiscipline?.includes(disc.id) ||
                    userInfo?.isAdmin
                )
                ?.map((disc) => (
                  <MenuItem key={disc.id} value={disc.id}>
                    {disc.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            id="description"
            label="Descrizione"
            type="text"
            variant="standard"
            fullWidth
            value={cat?.description}
            onChange={(event) =>
              setCat({ ...cat, description: event.target.value })
            }
          />
          <FormControlLabel
            label={process.env.REACT_APP_JRLABEL || "Junior"}
            control={
              <Checkbox
                id="isjr"
                checked={cat?.isjr > 0}
                onChange={(event) => {
                  setCat({ ...cat, isjr: event.target.checked ? "1" : "0" });
                }}
              />
            }
          />
          <FormControlLabel
            label={process.env.REACT_APP_COMPLABEL || "Competitivo"}
            control={
              <Checkbox
                id="iscomp"
                checked={cat?.iscomp > 0}
                onChange={(event) => {
                  setCat({ ...cat, iscomp: event.target.checked ? "1" : "0" });
                }}
              />
            }
          />
          <FormControlLabel
            label="Uso Interno"
            control={
              <Checkbox
                id="isinternal"
                checked={cat?.isinternal > 0}
                onChange={(event) => {
                  setCat({
                    ...cat,
                    isinternal: event.target.checked ? "1" : "0",
                  });
                }}
              />
            }
          />
          {signupDocTypes?.length>0 && (
            <FormControl variant="standard">
              <InputLabel htmlFor="doctype">Modulo Iscrizione</InputLabel>
              <Select
                id="doctype"
                label="Modulo Iscrizione"
                value={cat?.doctype || 0}
                onChange={(e) => setCat({ ...cat, doctype: e.target.value })}
              >
                {signupDocTypes.map((doc) => (
                  <MenuItem key={doc.id} value={doc.id}>
                    {doc.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDlgClose(false)}>Annulla</Button>
          <Button onClick={() => handleDlgClose(true)}>Salva</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      <>
        <EditCategoryDialog dialogCategory={editCategory} />
        {categories?.length > 0 && (
          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              title="Tipi di utenti"
              data={categories.filter(
                (cat) =>
                  userInfo?.mgrDiscipline?.includes(cat.discipline) ||
                  userInfo?.isAdmin
              )}
              columns={catColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                viewColumns: false,
                filter: false,
                rowsPerPage: 15,
                setTableProps: tableProps,
              }}
            />
          </Box>
        )}
      </>
    </>
  );
}
