export const TRANSFER_SIGNIN_REQUEST = 'TRANSFER_SIGNIN_REQUEST';
export const TRANSFER_SIGNIN_SUCCESS = 'TRANSFER_SIGNIN_SUCCESS';
export const TRANSFER_SIGNIN_FAIL = 'TRANSFER_SIGNIN_FAIL';

export const TRANSFER_LOADUSERS_REQUEST = 'TRANSFER_LOADUSERS_REQUEST';
export const TRANSFER_LOADUSERS_SUCCESS = 'TRANSFER_LOADUSERS_SUCCESS';
export const TRANSFER_LOADUSERS_FAIL = 'TRANSFER_LOADUSERS_FAIL';

export const TRANSFER_IMPORT_REQUEST = 'TRANSFER_IMPORT_REQUEST';
export const TRANSFER_IMPORT_SUCCESS = 'TRANSFER_IMPORT_SUCCESS';
export const TRANSFER_IMPORT_FAIL = 'TRANSFER_IMPORT_FAIL';