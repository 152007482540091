import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Send, CancelScheduleSend, Edit } from "@mui/icons-material";
import { makeStyles } from '@mui/styles';
import {
  importUser,
  loadTransfers,
  transfersignin,
} from "../actions/transferActions";
import { unloadProfile, updatetransfer } from "../actions/userActions";
import { checkNicknameExists } from "../utils/user";
import { loadCategories, loadParams } from "../actions/paramActions";
import { siteName } from "../utils/config";
import { getAllowedCategories } from "../utils/utils";

const useStyles = makeStyles({
  // entire table
  deftable: {
    "& td": { fontSize: "0.9rem", padding: 2, textAlign: "center" },
  },
});

export default function TransferScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const transferStore = useSelector((state) => state.transferStore);
  const { transferInfo, transferUsers, loading, error, messages } =
    transferStore;

  const paramList = useSelector((state) => state.paramList);
  const { categories, parameters, locations } = paramList;

  const [users, setUsers] = useState([]);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const [categoryNames, setCategoryNames] = useState([]);

  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    if (!categories?.length) {
      dispatch(loadCategories());
    } else {
      let names = [];
      categories.forEach((cat) => (names[cat.key] = cat.description));
      setCategoryNames(names);
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (!parameters?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, parameters]);

  function renderActions(value, { rowData }, updateValue) {
    const rowIndex = users.findIndex((u) => u.nickname === rowData[0]);
    const user = users.find((u) => u.nickname === rowData[0]);

    return (
      <Stack direction="row">
        {user.transfer === "TRANSFERED" ? (
          <IconButton disabled>
            <Send size="small" color="disabled" />
          </IconButton>
        ) : (
          <>
            <Tooltip title="Trasferisci Utente">
              <IconButton
                onClick={() => onPrepareTransfer(user, true, rowIndex)}
              >
                <Send size="small" color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Annulla Trasferimento">
              <IconButton
                onClick={() => onPrepareTransfer(user, false, rowIndex)}
              >
                <CancelScheduleSend size="small" color="warning" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Modifica Dati Import">
              <IconButton
                onClick={() => {
                  setSelectedRowId(rowIndex);
                  setOpenImportDialog(true);
                }}
              >
                <Edit size="small" color="action" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Stack>
    );
  }

  function tableProps() {
    return {
      className: classes.deftable,
    };
  }

  const transferColumns = [
    // TODO: utente creato e navigazione?
    { name: "nickname", label: "Nome utente" },
    { name: "fullname", label: "Cognome e Nome" },
    // TODO: solo pontevecchio?
    { name: "location", label: "Campo" },
    {
      name: "category",
      label: "Posizione",
      options: { customBodyRender: (value) => categoryNames[value] },
    },
    { name: "fullname", label: "Cognome e Nome" },
    { name: "birthyear", label: "Anno di nascita" },
    { name: "email", label: "e-Mail" },
    { name: "phonenrs", label: "Telefono" },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderActions },
    },
  ];

  async function onPrepareTransfer(user, dotransfer, rowId) {
    if (dotransfer) {
      const exists = await checkNicknameExists(user.nickname);
      if (exists) {
        setSelectedRowId(rowId);
        setOpenImportDialog(true);
      }
      dispatch(
        importUser(user.id, user.nickname, user.category, user.location)
      );
      dispatch(loadTransfers());
    } else {
      dispatch(updatetransfer(user.id, "READY"));
      dispatch(loadTransfers());
    }
  }

  useEffect(() => {
    dispatch(unloadProfile());
    if (!transferInfo) {
      setOpenLoginDialog(true);
    } else {
      setOpenLoginDialog(false);
    }
  }, [dispatch, transferInfo]);

  useEffect(() => {
    if (!transferUsers?.length) return;

    const map = transferUsers.map((user) => {
      return {
        ...user,
        fullname: `${user.surname} ${user.name}`,
        phonenrs: `${user.homenr} ${user?.phonenr && user.phonenr}`,
        birthyear: user.birthdate.substr(-4),
        location:
          locations.length > 0 ? locations[0].id : user.location || siteName,
        category: categories[0].key,
      };
    });

    setUsers(map);
  }, [transferUsers, categories, locations]);

  const LoginDialog = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleDlgClose = (login) => {
      if (login) {
        dispatch(transfersignin(username, password));
      }

      setOpenLoginDialog(false);
    };

    return (
      <Dialog open={openLoginDialog} onClose={() => handleDlgClose(false)}>
        <DialogTitle>Accesso a polisportiva partner</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="t_username"
            label="Username"
            type="text"
            variant="standard"
            fullWidth
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            margin="dense"
            id="t_password"
            label="Password"
            type="password"
            variant="standard"
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDlgClose(false)}>Annulla</Button>
          <Button onClick={() => handleDlgClose(true)}>Login</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ImportDialog = ({ rowId }) => {
    const [nickname, setNickname] = useState(users[rowId].nickname);
    const [nicknameError, setNickNameError] = useState(false);

    const [category, setCategory] = useState(
      categoryNames[users[rowId].category]
        ? users[rowId].category
        : categories[0].key
    );
    const [location, setLocation] = useState(
      locations?.length > 0
        ? locations[0].id
        : users[rowId].location || siteName
    );

    const checkExists = useCallback(async () => {
      if (!nickname) {
        setNickNameError(false);
        return true;
      }

      const exists = await checkNicknameExists(nickname);

      setNickNameError(exists);

      return exists;
    }, [nickname]);

    checkExists();

    const handleDlgClose = async (accept) => {
      if (accept) {
        const exists = await checkExists();
        if (exists) return;

        if (!category) return;
        if (!location) return;

        const user = { ...users[rowId], nickname, category, location };
        users[rowId] = user;
      }

      setOpenImportDialog(false);
    };

    const allowedCategories = () => {
      var catopts = getAllowedCategories(userInfo, users[rowId]);

      return catopts.map((opt) => (
        <MenuItem key={opt} value={opt}>
          {categoryNames[opt]}
        </MenuItem>
      ));
    };

    return (
      <Dialog open={openImportDialog} onClose={() => handleDlgClose(false)}>
        <DialogTitle>Modifica Dati Import</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="t_nickname"
            label="Nickname"
            type="text"
            variant="standard"
            fullWidth
            value={nickname}
            onChange={(event) => setNickname(event.target.value)}
            error={nicknameError}
            helperText={nicknameError ? "Nickname non disponibile" : ""}
          />
          {locations?.length === 0 ? (
            <TextField
              id="t_location"
              label="Palestra"
              type="text"
              value={location}
              variant="standard"
              fullWidth
              onChange={(event) => setLocation(event.target.value)}
            />
          ) : (
            <>
              <InputLabel id="location" variant="standard">
                Campo sportivo
              </InputLabel>
              <Select
                id="t_location"
                label="Campo Sportivo"
                value={location}
                required
                onChange={(event) => setLocation(event.target.value)}
              >
                {locations?.map((loc) => (
                  <MenuItem key={loc.id} value={loc.id}>
                    {loc.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          <InputLabel htmlFor="category" variant="standard">
            Posizione
          </InputLabel>
          <Select
            id="t_category"
            label="Posizione"
            value={category}
            required
            onChange={(event) => setCategory(event.target.value)}
          >
            {allowedCategories()}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDlgClose(false)}>Annulla</Button>
          <Button onClick={() => handleDlgClose(true)}>Modifica</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <Alert color="error">{error}</Alert>}
      {messages?.map((message, index) => (
        <Alert key={index} color="info">
          {message}
        </Alert>
      ))}
      <LoginDialog />
      {selectedRowId && <ImportDialog rowId={selectedRowId} />}
      {!openLoginDialog && (!transferInfo?.id || !users?.length) && (
        <Button onClick={() => setOpenLoginDialog(true)}>Login</Button>
      )}
      {transferInfo?.id && !users?.length && (
        <>
          <Button onClick={() => dispatch(loadTransfers())}>Reload</Button>
          <Alert color="info">Nessun utente</Alert>
        </>
      )}
      {users?.length > 0 && (
        <>
          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              data={users}
              columns={transferColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                download: false,
                // print: false,
                selectableRows: "none",
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 50, 100],
                setTableProps: tableProps,
              }}
            />
          </Box>
        </>
      )}
    </>
  );
}
