export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';
export const USER_SIGNIN_UPDATE = 'USER_SIGNIN_UPDATE';

export const USER_SIGNOUT = 'USER_SIGNOUT';

export const USER_LOADPROFILE_RESET = 'USER_LOADPROFILE_RESET';
export const USER_LOADPROFILE_REQUEST = 'USER_LOADPROFILE_REQUEST';
export const USER_LOADPROFILE_SUCCESS = 'USER_LOADPROFILE_SUCCESS';
export const USER_LOADPROFILE_FAIL = 'USER_LOADPROFILE_FAIL';
export const USER_LOADPROFILE_AUTHERROR = 'USER_LOADPROFILE_AUTHERROR';

export const USER_UPDATE_PASSWORD_REQUEST = 'USER_UPDATE_PASSWORD_REQUEST';
export const USER_UPDATE_LOCKED_REQUEST = 'USER_UPDATE_LOCKED_REQUEST';
export const USER_UPDATE_ENABLED_REQUEST = 'USER_UPDATE_ENABLED_REQUEST';
export const USER_UPDATE_ACL_REQUEST = 'USER_UPDATE_ACL_REQUEST';
export const USER_UPDATE_PARENT_REQUEST = 'USER_UPDATE_PARENT_REQUEST';
export const USER_UPDATE_PAYMENTS_REQUEST = 'USER_UPDATE_PAYMENTS_REQUEST';
export const USER_UPDATE_DOCUMENTS_REQUEST = 'USER_UPDATE_DOCUMENTS_REQUEST';
export const USER_UPDATE_TRANSFER_REQUEST = 'USER_UPDATE_TRANSFER_REQUEST';
export const USER_UPDATE_DATA_REQUEST = 'USER_UPDATE_DATA_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';

export const USER_SET_STATE = 'USER_SET_STATE';
