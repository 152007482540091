import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCategories, loadParams } from "../actions/paramActions";
import { Add, Delete } from "@mui/icons-material";
import { loadPricelist, updatePricelist } from "../utils/utils";
import { signout, unloadProfile } from "../actions/userActions";

const gpltypes = [
  { id: "category", label: "Tipo Utente" },
  { id: "location", label: "Campo sportivo" }, // TODO: solo se locations.length
  { id: "signup", label: "Tipo Iscrizione" },
  { id: "numtrainings", label: "Numero Allenamenti" }, // TODO: solo se allenamenti disponibili
  { id: "age", label: "Età" },
];

export default function PricelistScreen() {
  const paramList = useSelector((state) => state.paramList);
  const { parameters, categories, locations, signupTypes, loading, error } =
    paramList;

  const [genpricelist, setGenPricelist] = useState([]);
  const [pricelist, setPricelist] = useState([]);
  const [fingerprints, setFingerprints] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [modifyOn, setModifyOn] = useState(false);

  const dispatch = useDispatch();

  //   useEffect(() => {
  //     console.log("gpl", genpricelist);
  //   }, [genpricelist]);

  // useEffect(() => {
  //   console.log("pl", pricelist);
  // }, [pricelist]);

  // useEffect(() => {
  //   console.log("fp", fingerprints);
  // }, [fingerprints]);

  const plFromType = useCallback(
    (type) => {
      switch (type) {
        case "category":
          return categories?.map((cat) => {
            return { fingerprint: cat.key, label: cat.description };
          });
        case "location":
          return locations?.map((loc) => {
            return { fingerprint: loc.id, label: loc.name };
          });
        case "signup":
          return signupTypes?.map((sig) => {
            return { fingerprint: sig, label: sig };
          });
        case "age":
          return [
            { fingerprint: "agelt16", label: "Età inferiore a 16" },
            { fingerprint: "ageother", label: "Altro" },
          ];
        case "numtrainings":
          return [
            { fingerprint: "tr1", label: "Un allenamento" },
            { fingerprint: "tr2", label: "Due allenamenti" },
            { fingerprint: "tr3", label: "Tre allenamenti" },
          ];
        default:
          break;
      }

      return [];
    },
    [categories, locations, signupTypes]
  );

  const generatePriceList = useCallback(
    (gpl, curpricelist) => {
      let pl = [];
      let fps = [];
      gpl.forEach((p) => {
        if (p.fingerprint !== "") {
          fps[p.fingerprint] = { ...fps[p.fingerprint], enabled: true };
        }
        // criteri generici
        const pllist = plFromType(p.type);
        if (pllist?.length > 0) {
          // primo giro
          if (pl.length === 0) {
            pllist.forEach((pp) => {
              pl.push({
                fingerprint: pp.fingerprint,
                label: pp.label,
                count: 1,
                value: 0,
              });
              fps[pp.fingerprint] = {
                label: pp.label,
                count: 1,
                enabled: false,
              };
            });
          } else {
            let newpl = [];
            pllist.forEach((pp) => {
              pl.forEach((ppr) => {
                if (
                  p.fingerprint === "" ||
                  ppr.fingerprint === p.fingerprint ||
                  ppr.fingerprint.startsWith(p.fingerprint + "_")
                )
                  newpl.push({
                    fingerprint: ppr.fingerprint + "_" + pp.fingerprint,
                    label: ppr.label + " & " + pp.label,
                    count: ppr.count + 1,
                    value: 0,
                  });
                fps[ppr.fingerprint + "_" + pp.fingerprint] = {
                  label: ppr.label + " & " + pp.label,
                  count: ppr.count + 1,
                  enabled: false,
                };
              });
            });
            if (p.fingerprint === "") pl = [...newpl];
            else pl = [...newpl, ...pl];
          }
        }
      });

      const maxcount = pl.reduce(function (max, p) {
        return p.count > max ? p.count : max;
      }, 0);
      pl.forEach((p) => {
        p.count = maxcount;
        const idx = curpricelist.findIndex(
          (p1) => p1.fingerprint === p.fingerprint
        );
        if (idx >= 0) p.value = curpricelist[idx].value;
      });

      // console.log("pl fps", pl, fps);

      setGenPricelist(gpl);
      setPricelist(pl);
      setFingerprints(fps);
    },
    [plFromType]
  );

  useEffect(() => {
    async function fetchData() {
      const plobj = await loadPricelist();
      if (plobj?.error === undefined) {
        generatePriceList(plobj.rules, plobj.items);
        setDataLoaded(true);
      } else {
        console.log("cannot get price list", plobj.message);
        if (plobj.exit) dispatch(signout());
      }
    }

    dispatch(unloadProfile());
    if (!parameters?.length) {
      dispatch(loadParams());
    }
    if (!dataLoaded) {
      fetchData();
    }
  }, [dispatch, generatePriceList, parameters, dataLoaded]);

  useEffect(() => {
    if (!categories?.length) {
      dispatch(loadCategories());
    }
  }, [dispatch, categories]);

  const onAddGPL = useCallback(
    (fingerprint) => {
      const gpl = [...genpricelist];
      gpl.push({ fingerprint, type: "" });

      generatePriceList(gpl, pricelist);
    },
    [genpricelist, pricelist, generatePriceList]
  );

  const onRemoveGPL = useCallback(
    (index) => {
      const gpl = [...genpricelist];
      gpl.splice(index, 1);

      generatePriceList(gpl, pricelist);
    },
    [genpricelist, pricelist, generatePriceList]
  );

  const onChangeGPLType = useCallback(
    (index, type) => {
      const gpl = [...genpricelist];
      gpl[index].type = type;

      generatePriceList(gpl, pricelist);
    },
    [genpricelist, pricelist, generatePriceList]
  );

  const setPrice = (price) => {
    const pl = [...pricelist];
    const idx = pl.findIndex((p) => p.fingerprint === price.fingerprint);
    if (idx >= 0) {
      pl[idx] = { ...price };
      setPricelist(pl);
    }
  };

  function handleModifyButton() {
    setModifyOn(!modifyOn);
  }

  async function handlePricelistSave() {
    const plobj = await updatePricelist({
      rules: genpricelist,
      items: pricelist,
    });

    if (!plobj?.error) generatePriceList(plobj.rules, plobj.items);
    else {
      console.log("cannot update price list", plobj.error);
      if (plobj.exit) dispatch(signout());
    }
  }

  const criteria = useCallback(
    (generic, name) => {
      return genpricelist.map(
        (gpl, index) =>
          (generic ? gpl.fingerprint === "" : gpl.fingerprint !== "") && (
            <React.Fragment key={index}>
              {fingerprints[gpl.fingerprint]?.enabled && (
                <Grid
                  item
                  xs={3 + 2 * (fingerprints[gpl.fingerprint].count - 1)}
                >
                  <TextField
                    margin="dense"
                    //   label="Tariffa"
                    variant="outlined"
                    fullWidth
                    value={fingerprints[gpl.fingerprint].label}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={2}>
                <FormControl key={index} fullWidth>
                  <InputLabel id={`${index}`}>
                    {`${name} ${index + 1}`}
                  </InputLabel>
                  <Select
                    id={`${index}`}
                    label={`${name} ${index + 1}`}
                    value={gpl.type}
                    variant="standard"
                    required
                    disabled={!modifyOn}
                    onChange={(e) => onChangeGPLType(index, e.target.value)}
                  >
                    {gpltypes?.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disabled={!modifyOn}
                  onClick={() => onRemoveGPL(index)}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </React.Fragment>
          )
      );
    },
    [genpricelist, fingerprints, modifyOn, onChangeGPLType, onRemoveGPL]
  );

  return (
    <>
      {loading && <LinearProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      <Box component="form" autoComplete="off">
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Criteri generici</Typography>
          </Grid>
          {criteria(true, "Criterio")}
          <Grid item>
            <IconButton disabled={!modifyOn} onClick={() => onAddGPL("")}>
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Criteri specifici</Typography>
          </Grid>
          {criteria(false, "Sub Criterio")}
          <Grid item xs={2}>
            <Button
              variant="contained"
              color={modifyOn ? "warning" : "primary"}
              onClick={() => handleModifyButton()}
            >
              {modifyOn ? "Termina" : "Modifica"}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              disabled={!modifyOn}
              color="success"
              onClick={() => handlePricelistSave()}
            >
              Salva
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Tariffe</Typography>
          </Grid>
          {pricelist?.map((price) =>
            fingerprints[price.fingerprint]?.enabled === false ? (
              <React.Fragment key={price.fingerprint}>
                <Grid item xs={3 + 2 * (price.count - 1)}>
                  <TextField
                    //   margin="dense"
                    //   label="Tariffa"
                    variant="outlined"
                    fullWidth
                    value={price.label}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    size="small"
                    disabled={!modifyOn}
                    onClick={() => {
                      onAddGPL(price.fingerprint);
                    }}
                  >
                    <Add />
                  </IconButton>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    margin="dense"
                    // id="price"
                    label="Tariffa"
                    type="number"
                    variant="standard"
                    fullWidth
                    required
                    disabled={!modifyOn}
                    value={price.value}
                    onChange={(event) =>
                      setPrice({ ...price, value: event.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={6 - 2 * (price.count - 1)}></Grid>
              </React.Fragment>
            ) : null
          )}
        </Grid>
      </Box>
    </>
  );
}
