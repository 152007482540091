import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { loadParams, setDiscipline } from "../actions/paramActions";
import {
  loadAllAttendees,
  modifyAttendeesPresence,
} from "../actions/attActions";
import { loadUsers } from "../actions/usersActions";
import { unloadProfile } from "../actions/userActions";
import { useNavigate } from "react-router-dom";

export default function AttendeesScreen() {
  const [myfilter, setMyfilter] = useState({
    discipline: "",
    location: "",
    group: "",
    period: "week",
    refDate: moment(),
  });

  const [attendeeColumns, setAttendeeColumns] = useState([]);
  const [mattendees, setMattendees] = useState([]);

  const [showOrphans, setShowOrphans] = useState(false);
  const [usersWithoutGroup, setUsersWithoutGroup] = useState([]);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const paramList = useSelector((state) => state.paramList);
  const { locations, disciplines, groupNames } = paramList;
  const usersList = useSelector((state) => state.usersList);
  const { userProfiles } = usersList;

  const attendee = useSelector((state) => state.attendee);
  const { loading, message, error, attendees } = attendee;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeDiscipline = useCallback(
    (disc) => {
      dispatch(setDiscipline(disc));
      dispatch(loadAllAttendees(disc));
      setMyfilter((oldfilter) => {
        return { ...oldfilter, discipline: disc, location: "", group: "" };
      });
    },
    [dispatch]
  );

  const useStyles = makeStyles({
    ok: {
      backgroundColor: "#6F6",
    },
    sigerr: {
      backgroundColor: "#F00",
    },
    certwarn: {
      backgroundColor: "#FF2",
    },
    certerr: {
      backgroundColor: "#F00",
    },

    // mui-datatable row
    error: {
      "& td": { backgroundColor: "#F00" },
    },
    warning: {
      "& td": { backgroundColor: "#FF2" },
    },

    // entire table (edit presenze)
    edittable: {
      "& td": { fontSize: "0.8rem", padding: 0.3 },
    },
  });

  const classes = useStyles();

  function cellProps(cellValue, rowIndex, columnIndex) {
    if (mattendees[rowIndex] === undefined) return {};

    const user = mattendees[rowIndex].user;

    const isAssocColumn = columnIndex === 0 || columnIndex === 3;
    const isCertColumn = columnIndex === 5;

    return {
      className: clsx({
        [classes.sigerr]: isAssocColumn && (user.assoctype === "" || user.assoccomment !== ""),
        [classes.certerr]: isCertColumn && user.certerr !== "" && !user.certwarning,
        [classes.certwarn]:
          isCertColumn && user.certerr !== "" && user.certwarning,
        [classes.ok]: true,
      }),
    };
  }

  const sattendeeColumns = [
    {
      name: "fullname",
      label: "Cognome Nome",
      options: {
        customBodyRender: (value, { rowIndex, columnIndex }, updateValue) => {
          return (
            <Stack direction="row">
              <Tooltip title="Modifica Dati Utente">
                <IconButton
                  size="small"
                  onClick={() =>
                    navigate(`/profile/${mattendees[rowIndex].user.id}`)
                  }
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Box>
                {mattendees[rowIndex].user.surname}{" "}
                {mattendees[rowIndex].user.name}
              </Box>
            </Stack>
          );
        },
        setCellProps: cellProps,
      },
    },
    {
      name: "email",
      label: "e-Mail",
      options: {
        customBodyRender: (value, { rowIndex, columnIndex }, updateValue) =>
          mattendees[rowIndex].user.email,
      },
    },
    {
      name: "phonenr",
      label: "Telefono",
      options: {
        customBodyRender: (value, { rowIndex, columnIndex }, updateValue) =>
          mattendees[rowIndex].user.phonenr,
      },
    },
    {
      name: "sigup",
      label: "Iscrizione",
      options: {
        customBodyRender: (value, { rowIndex, columnIndex }, updateValue) =>
          mattendees[rowIndex].user.assoctype || "Non iscritto",
        setCellProps: cellProps,
      },
    },
    {
      name: "birthdate",
      label: "Data di nascita",
      options: {
        customBodyRender: (value, { rowIndex, columnIndex }, updateValue) =>
          mattendees[rowIndex].user.birthdate,
      },
    },
    {
      name: "certerr",
      label: "Certificato",
      options: {
        customBodyRender: (value, { rowIndex, columnIndex }, updateValue) =>
          mattendees[rowIndex].user.certerr ||
          mattendees[rowIndex].user.certexpire,
        setCellProps: cellProps,
      },
    },
  ];

  const orphanColumns = [
    {
      name: "fullname",
      label: "Cognome Nome",
      options: {
        customBodyRender: (value, { rowIndex, columnIndex }, updateValue) => {
          return (
            <Stack direction="row">
              <Tooltip title="Modifica Dati Utente">
                <IconButton
                  size="small"
                  onClick={() =>
                    navigate(`/profile/${usersWithoutGroup[rowIndex].id}`)
                  }
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Box>
                {usersWithoutGroup[rowIndex].surname}{" "}
                {usersWithoutGroup[rowIndex].name}
              </Box>
            </Stack>
          );
        },
      },
    },
    {
      name: "email",
      label: "e-Mail",
    },
    {
      name: "location",
      label: "Campo Sportivo",
    },
  ];

  useEffect(() => {
    let myCols = [];

    let mstart = myfilter.refDate;
    let mend = mstart;

    switch (myfilter.period) {
      case "day":
        mend = moment(mstart).add(1, "days");
        break;
      case "week":
        mstart = moment(myfilter.refDate).startOf("week");
        mend = moment(mstart).add(7, "days");
        break;
      case "month":
        mstart = moment(myfilter.refDate).startOf("month");
        mend = moment(mstart).add(1, "months");
        break;
      default:
        break;
    }

    for (let d = moment(mstart); d.isBefore(mend); d.add(1, "days")) {
      const dd = d.format("DD/MM/YY");
      const ddsql = d.format("DD/MM/YYYY");

      const isweekend = d.weekday() === 0 || d.weekday() === 6;

      myCols.push({
        name: dd,
        label: dd,
        options: {
          customBodyRender: (value, { rowIndex, columnIndex }) => {
            const p = mattendees[rowIndex]?.attlist.find(
              (att) => att.dd === dd
            );
            const present = p?.present || false;

            return (
              <Stack direction="row">
                <IconButton
                  onClick={() =>
                    dispatch(
                      modifyAttendeesPresence(
                        myfilter.discipline,
                        ddsql,
                        myfilter.group,
                        mattendees[rowIndex].user.id,
                        !present
                      )
                    )
                  }
                >
                  {p ? (
                    present ? (
                      <CheckBoxIcon color="success" />
                    ) : (
                      <CheckBoxOutlineBlankIcon color="success" />
                    )
                  ) : (
                    <CheckBoxOutlineBlankIcon color="primary" />
                  )}
                </IconButton>
              </Stack>
            );
          },
          setCellProps: () => {
            return {
              style: {
                alignContent: "center",
                backgroundColor: isweekend ? "#88F" : "#FFF",
              },
            };
          },
        },
      });
    }

    setAttendeeColumns(myCols);
  }, [myfilter, mattendees, dispatch]);

  useEffect(() => {
    if (myfilter.group === "") {
      setMattendees([]);
      return;
    }

    let ma = [];

    userProfiles
      .filter((u) => u.group === myfilter.group && u.enabled)
      .forEach((u) => {
        let attlist = [];

        attendees
          .filter((att) => att.user.id === u.id)
          .forEach((att) => {
            attlist.push({
              present: att.present,
              when: moment(att.when, "DD/MM/YYYY"),
              dd: moment(att.when, "DD/MM/YYYY").format("DD/MM/YY"),
            });
          });

        ma.push({
          attlist,
          user: u,
        });
      });

    setMattendees(ma);
  }, [myfilter.group, userProfiles, attendees]);

  useEffect(() => {
    dispatch(unloadProfile());
    if (userInfo?.aclDiscipline) {
      changeDiscipline(userInfo.aclDiscipline[0]);
    }
  }, [userInfo, changeDiscipline, dispatch]);

  useEffect(() => {
    if (!disciplines?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, disciplines]);

  useEffect(() => {
    if (!userProfiles?.length) {
      dispatch(loadUsers());
    } else {
      //TODO: anche gruppi non più validi?
      setUsersWithoutGroup(
        userProfiles.filter((u) => u.group === "" && u.enabled && !u.isinternal)
      );
    }
  }, [dispatch, userProfiles]);

  function editTableProps() {
    return {
      className: classes.edittable,
    };
  }

  function updateRefDate(value) {
    if (value === 0) {
      setMyfilter({
        ...myfilter,
        refDate: moment(),
      });

      return;
    }

    const d = moment(myfilter.refDate);

    switch (myfilter.period) {
      case "day":
        d.add(value, "days");
        break;
      case "week":
        d.add(value * 7, "days");
        break;
      case "month":
        d.add(value, "months");
        break;
      default:
        break;
    }

    setMyfilter({
      ...myfilter,
      refDate: d,
    });
  }

  return (
    <>
      {loading && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      {disciplines && userInfo?.aclDiscipline?.length > 0 && (
        <Grid container spacing={1} padding={1} mt={2}>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="discipline">Sport</InputLabel>
              <Select
                id="discipline"
                value={myfilter.discipline}
                onChange={(e) => changeDiscipline(e.target.value)}
              >
                {disciplines
                  ?.filter((disc) => userInfo?.aclDiscipline.includes(disc.id))
                  .map((disc) => (
                    <MenuItem key={disc.id} value={disc.id}>
                      {disc.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="location">Campo Sportivo</InputLabel>
              <Select
                id="location"
                value={myfilter.location}
                onChange={(e) =>
                  setMyfilter({
                    ...myfilter,
                    location: e.target.value,
                    group: "",
                  })
                }
              >
                {locations.map((loc) => (
                  <MenuItem key={loc.id} value={loc.id}>
                    {loc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="group">Gruppo</InputLabel>
              <Select
                id="group"
                value={myfilter.group}
                onChange={(e) =>
                  setMyfilter({
                    ...myfilter,
                    group: e.target.value,
                  })
                }
              >
                {Object.entries(groupNames)
                  ?.filter(
                    ([key, value]) => value.location === myfilter.location
                  )
                  .map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="group">Periodo</InputLabel>
              <Select
                id="period"
                value={myfilter.period}
                onChange={(e) =>
                  setMyfilter({
                    ...myfilter,
                    period: e.target.value,
                  })
                }
              >
                {[
                  { key: "day", name: "Giorno" },
                  { key: "week", name: "Settimana" },
                  { key: "month", name: "Mese" },
                ].map((p) => (
                  <MenuItem key={p.key} value={p.key}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={() => updateRefDate(-1)}>Precedente</Button>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={() => updateRefDate(0)}>Oggi</Button>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={() => updateRefDate(1)}>Successivo</Button>
          </Grid>
          {userInfo?.isManager && usersWithoutGroup?.length > 0 && (
            <Grid item xs={2}>
              <Button
                onClick={() =>
                  setShowOrphans((old) => {
                    return !old;
                  })
                }
              >
                {showOrphans ? "Nascondi" : "Mostra"} utenti senza gruppo
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      {groupNames && myfilter.group !== "" && (
        <>
          {mattendees?.length > 0 && (
            <Box margin={2} flex={true} flexGrow="1">
              <MUIDataTable
                title={`Presenze ${groupNames[myfilter.group].name}`}
                data={mattendees}
                columns={[...sattendeeColumns, ...attendeeColumns]}
                options={{
                  selectableRowsHideCheckboxes: true,
                  setTableProps: editTableProps,
                  download: false,
                  print: false,
                  viewColumns: true,
                  filter: true,
                  search: false,
                  sort: false,
                  rowsPerPage: 100,
                  // setRowProps: rowProps,
                }}
              />
            </Box>
          )}
        </>
      )}
      {usersWithoutGroup?.length > 0 && showOrphans && (
        <Box margin={2} flex={true} flexGrow="1">
          <MUIDataTable
            title="Utenti senza gruppo"
            data={usersWithoutGroup}
            columns={orphanColumns}
            options={{
              selectableRowsHideCheckboxes: true,
              setTableProps: editTableProps,
              download: false,
              print: false,
              viewColumns: true,
              filter: true,
              search: false,
              sort: false,
              rowsPerPage: 100,
              // setRowProps: rowProps,
            }}
          />
        </Box>
      )}
    </>
  );
}
