import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadUsers } from "../actions/usersActions";
import {
  Alert,
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import { Send } from "@mui/icons-material";
import { sendUserAlert, setUserAlertEnable, unloadProfile } from "../actions/userActions";
import { loadCategories, loadParams } from "../actions/paramActions";
import { findDiscByCategory } from "../utils/utils";

const useStyles = makeStyles({
  filterTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "4px 4px 4px 4px",
    },
  },

  // mui-datatable cell
  uisperr: {
    backgroundColor: "#FC4",
  },
  fidalerr: {
    backgroundColor: "#FAA",
  },
  certtovalidate: {
    backgroundColor: "#2FF",
  },
  certwarning: {
    backgroundColor: "#DD2",
  },
  certerr: {
    backgroundColor: "#F88",
  },

  // mui-datatable row
  disabled: {
    "& td": { backgroundColor: "#AAA" },
  },
  // entire table
  deftable: {
    "& td": { fontSize: "0.8rem", padding: 2, textAlign: "center" },
  },
});

export default function UserAlertsScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const usersList = useSelector((state) => state.usersList);
  const { userProfiles, loading, error, message } = usersList;
  const paramList = useSelector((state) => state.paramList);
  const { categories,disciplines } = paramList;

  const [mappedUsers, setMappedUsers] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();

  function renderFirst(value, { rowData, columnIndex, rowIndex }, updateValue) {
    const user = mappedUsers.find(
      (u) => parseInt(u.id) === parseInt(rowData[0])
    );

    return user ? (
      <Stack direction="row">
        <Tooltip title="Modifica Dati Utente">
          <IconButton
            size="small"
            onClick={() => navigate(`/profile/${user.id}`)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Box fontSize={"0.8rem"} margin="auto">
          {`(${user.id}${!user.locked ? " **" : ""}${
            !user.enabled ? " [D]" : ""
          })`}
        </Box>
      </Stack>
    ) : null;
  }

  function renderAlert(value, { rowData, columnIndex }, updateValue) {
    const user = mappedUsers.find(
      (u) => parseInt(u.id) === parseInt(rowData[0])
    );

    if (!user) return value;

    const alerttype =
      columnIndex === 6 ? "CERT" : columnIndex === 7 ? "UISP" : "FIDAL";
    const alertsend =
      (alerttype === "CERT" && (user.certwarning || user.certerr !== "")) ||
      (alerttype === "UISP" && user.uisperr !== "") ||
      (alerttype === "FIDAL" && user.fidalerr !== "");
    const alertexpdate =
      (alerttype === "CERT" && (user.certexpire || "--")) ||
      (alerttype === "UISP" && (user.uispexpire || "--")) ||
      (alerttype === "FIDAL" && (user.fidalexpire || "--"));
    const expired =
      alertexpdate === "--" ||
      (alerttype === "CERT" && user.certexpired) ||
      (alerttype === "UISP" && user.uispexpired) ||
      (alerttype === "FIDAL" && user.fidalexpired);

    const discipline = findDiscByCategory(
      user.category,
      categories,
      disciplines
    );

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Box>{value}</Box>
          {alertsend && (
            <Tooltip title="Invia mail">
              <IconButton
                onClick={() =>
                  onSendUserAlert(
                    user.id,
                    expired,
                    alerttype,
                    alertexpdate,
                    discipline
                  )
                }
              >
                <Send size="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Box>
    );
  }

  function renderActions(value, { rowData, columnIndex }, updateValue) {
    const user = mappedUsers.find(
      (u) => parseInt(u.id) === parseInt(rowData[0])
    );

    return (user &&
      userInfo?.acl !== undefined &&
      userInfo.acl[user.category] === "full") ||
      userInfo?.isAdmin ? (
      <Stack direction="row">
        <Tooltip title="Abilitazione Alert">
          <IconButton
            onClick={() => onEnableUserAlert(user.id, !user.alertenabled)}
          >
            {user.alertenabled ? (
              <ToggleOnIcon size="small" color="success" />
            ) : (
              <ToggleOffIcon size="small" />
            )}
          </IconButton>
        </Tooltip>
      </Stack>
    ) : null;
  }

  function tableProps() {
    return {
      className: classes.deftable,
    };
  }

  function rowProps(row, dataIndex) {
    const user = mappedUsers[dataIndex];

    return {
      className: clsx({
        [classes.disabled]: !user?.enabled,
      }),
    };
  }

  function cellProps(cellValue, rowIndex, columnIndex) {
    const user = mappedUsers[rowIndex];

    return {
      className: clsx({
        [classes.certtovalidate]:
          user.certtovalidate && columnIndex === 6 && user.enabled,
        [classes.certwarn]:
          !user.certtovalidate &&
          user.certwarning &&
          columnIndex === 6 &&
          user.enabled,
        [classes.certerr]:
          !user.certtovalidate &&
          !user.certwarning &&
          user.certerr !== "" &&
          columnIndex === 6 &&
          user.enabled,
        [classes.uisperr]:
          user.uisperr !== "" && columnIndex === 7 && user.enabled,
        [classes.fidalerr]:
          user.fidalerr !== "" && columnIndex === 8 && user.enabled,
      }),
    };
  }

  const usersColumns = [
    {
      name: "longid",
      label: "Iscrizione",
      options: { customBodyRender: renderFirst },
    },
    { name: "fullname", label: "Cognome e Nome" },
    { name: "assoctype", label: "Tesseramento" },
    { name: "assocpaid", label: "Quota versata" },
    // { name: "birthyear", label: "Anno di nascita" },
    { name: "email", label: "e-Mail" },
    { name: "lastalert", label: "Ultimi Alert" },
    {
      name: "cert",
      label: "Scadenza Certificato Medico",
      options: { customBodyRender: renderAlert, setCellProps: cellProps },
    },
    {
      name: "uisp",
      label: "UISP",
      options: { customBodyRender: renderAlert, setCellProps: cellProps },
    },
    {
      name: "fidal",
      label: "FIDAL",
      options: { customBodyRender: renderAlert, setCellProps: cellProps },
    },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderActions },
    },
  ];

  function onEnableUserAlert(userId, enable) {
    dispatch(setUserAlertEnable(userId, enable));
  }

  function onSendUserAlert(userId, expired, alerttype, expiredate, discipline) {
    dispatch(sendUserAlert(userId, expired, alerttype, expiredate, discipline));
  }

  useEffect(() => {
    dispatch(unloadProfile());
    dispatch(loadUsers());
  }, [dispatch]);

  useEffect(() => {
    if (!categories?.length) {
      dispatch(loadCategories());
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (!disciplines?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, disciplines]);

  useEffect(() => {
    const usersmap = userProfiles
      ?.filter(
        (user) =>
          user.certexpired ||
          user.certwarning ||
          user.certerr !== "" ||
          user.uispexired ||
          user.uispwarning ||
          user.fidalexired ||
          user.fidalwarning
      )
      .map((user) => {
        return {
          ...user,
          fullname: `${user.surname.toUpperCase()} ${user.name.toUpperCase()}`,
          assoctype:
            user.assocyear > 0 ? `${user.assoctype} (${user.assocyear})` : "",
          assocyear: user.assocyear > 0 ? user.assoccomment : "",
          assocpaid:
            user.assocyear > 0 ? `${user.assocpaid} / ${user.assoceuro}` : "",
          fidal: `${user.fidalcard} (${user.fidalexpire})`,
          uisp: `${user.uispcard} (${user.uispexpire})`,
          phonenrs: `${user.homenr} ${user?.phonenr && user.phonenr}`,
          certtovalidate: user.certfilename !== "" && user.certerr,
          cert: user.certexpire || user.certerr,
          lastalert: Object.entries(user.lastalert)
            .map(([key, value]) => `${key}: ${value}`)
            .join(", "),
          // birthyear: user.birthdate.substr(-4),
        };
      });

    setMappedUsers(usersmap);
  }, [userProfiles]);

  return (
    <>
      {loading && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {error && <Alert color="error">{error}</Alert>}
      {mappedUsers && (
        <>
          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              data={mappedUsers}
              columns={usersColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                download: false,
                // print: false,
                searchAlwaysOpen: true,
                selectableRows: "none",
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 50, 100],
                setTableProps: tableProps,
                setRowProps: rowProps,
              }}
            />
          </Box>
        </>
      )}
    </>
  );
}
