import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCategories } from "../actions/paramActions";
import { canTransfer } from "../utils/config";

export const AclDialog = ({ dlgOpen, aclUser, onConfirm }) => {
  const paramList = useSelector((state) => state.paramList);
  const { categories } = paramList;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const [acl, setAcl] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (aclUser?.id) {
      setAcl(aclUser);
    }
  }, [aclUser]);

  useEffect(() => {
    if (!categories?.length) {
      dispatch(loadCategories());
    }
  }, [dispatch, categories]);

  return (
    <Dialog
      open={dlgOpen}
      onClose={() => onConfirm(false, null)}
      maxWidth={"md"}
    >
      <DialogTitle>
        Modifica Diritti di accesso per {`${acl?.name} ${acl?.surname}`}
      </DialogTitle>
      <DialogContent>
        {categories?.filter((cat) => (userInfo?.isAdmin || userInfo?.mgrDiscipline.includes(cat.discipline)) )?.map((cat) => (
          <React.Fragment key={cat.id}>
            <FormControl>
              <FormLabel id={`acl${cat.id}`}>
                {cat.description} {`[${cat.key}]`}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby={`acl${cat.id}`}
                name={`radio${cat.id}`}
                value={acl?.acl?.[cat.key] || "none"}
                onChange={(event) => {
                  const aclvalue = {
                    ...acl.acl,
                    [cat.key]: event.target.value,
                  };
                  setAcl({ ...acl, acl: aclvalue });
                }}
              >
                <FormControlLabel
                  value="full"
                  control={
                    <Radio inputProps={{ style: { fontSize: "0.8rem" } }} />
                  }
                  label="Completo"
                />
                <FormControlLabel
                  value="read"
                  control={
                    <Radio inputProps={{ style: { fontSize: "0.8rem" } }} />
                  }
                  label="Lettura"
                />
                <FormControlLabel
                  value="limited"
                  control={
                    <Radio inputProps={{ style: { fontSize: "0.8rem" } }} />
                  }
                  label="Limitato"
                />
                {canTransfer && (
                  <FormControlLabel
                    value="canTransfer"
                    control={
                      <Radio inputProps={{ style: { fontSize: "0.8rem" } }} />
                    }
                    label="Trasferimento"
                  />
                )}
                <FormControlLabel
                  value="none"
                  control={
                    <Radio inputProps={{ style: { fontSize: "0.8rem" } }} />
                  }
                  label="Nessuno"
                />
              </RadioGroup>
            </FormControl>
            <FormControlLabel
              label="Notifica mail"
              sx={{ ml: 1 }}
              control={
                <Checkbox
                  checked={acl?.acl?.[`mail_${cat.key}`] || false}
                  disabled={acl?.acl && acl?.acl[cat.key] === "none"}
                  onChange={(event) => {
                    const aclvalue = {
                      ...acl.acl,
                      [`mail_${cat.key}`]: event.target.checked,
                    };
                    setAcl({ ...acl, acl: aclvalue });
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onConfirm(false, null)}>Annulla</Button>
        <Button onClick={() => onConfirm(true, acl)}>Salva</Button>
      </DialogActions>
    </Dialog>
  );
};
