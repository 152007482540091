import { Alert, Box, Button, FormControl, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { verifyMail } from "../utils/user";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../actions/userActions";
import { loadCategories, loadParams } from "../actions/paramActions";
import { decode } from "base-64";

export default function ConfirmMailScreen() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [short, setShort] = useState(false);
  const [complete, setComplete] = useState(false);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userState } = userSignin;

  const paramList = useSelector((state) => state.paramList);
  const { categories, disciplines } = paramList;

  const params = useParams();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = async (event) => {
    event.preventDefault();

    setLoading(true);
    setErrorMessage("");
    setMessage("");
    const reply = await verifyMail(params.mailtoken, true, "");
    setLoading(false);
    if (reply.error) {
      setErrorMessage(reply.message);
    } else {
      setMessage(reply.message);
    }
  };

  useEffect(() => {
    const s_short = searchParams.get("short");
    if (s_short) setShort(s_short);
  }, [searchParams]);

  useEffect(() => {
    if (userState?.message) {
      setErrorMessage("");
      setMessage(userState.message);
    }
  }, [userState]);

  useEffect(() => {
    async function verify(discName) {
      setLoading(true);
      setErrorMessage("");
      setMessage("");
      const reply = await verifyMail(params.mailtoken, false, discName);
      setLoading(false);
      if (reply.error) {
        setErrorMessage(reply.message);
      } else {
        setMessage(reply.message);
        setComplete(true);
        dispatch(signin(reply.nickname, "", reply.tmpID));
      }
    }

    if (!disciplines?.length) {
      dispatch(loadParams());
    }
    if (!categories?.length) {
      dispatch(loadCategories());
    }

    if (
      categories?.length > 0 &&
      disciplines?.length > 0 &&
      params?.mailtoken !== "-" &&
      !params?.mailtoken.startsWith("UID")
    ) {
      const token_dec = decode(params.mailtoken);
      const token = JSON.parse(token_dec);

      let discName = "";
      const cat = categories.find((cat) => cat.key === token.userCategory);
      if (cat?.discipline) {
        const index = disciplines.findIndex(
          (disc) => disc.id === cat.discipline
        );
        if (index >= 0) discName = disciplines[index].name;
      }

      verify(discName);
    }
  }, [params, categories, disciplines, dispatch]);

  return (
    <>
      {loading && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!complete && (
          <>
            <Box>
              Controlla nella casella di posta il codice di verifica per
              confermare la mail.
            </Box>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
              <Button
                variant="contained"
                disabled={params?.mailtoken === "-"}
                onClick={submitHandler}
              >
                Invia nuova mail di verifica
              </Button>
            </FormControl>
          </>
        )}
        {short && (
          <Box sx={{ mt: 2 }}>
            Sarai ricontattato per fissare le giornate di prova. A volte serve
            un giorno o anche due, ma rispondiamo sempre.
          </Box>
        )}
        {complete && (
          <>
            <Box sx={{ mt: 2 }}>
              Grazie per aver confermato la mail. Ora puoi verificare i dati del
              profilo e caricare la visita medica.
            </Box>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
              <Button
                variant="contained"
                disabled={params?.mailtoken === "-"}
                onClick={() => navigate(`/profile/${userInfo.id}/docs`)}
              >
                Carica la visita medica
              </Button>
            </FormControl>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
              <Button
                variant="contained"
                disabled={params?.mailtoken === "-"}
                onClick={() => navigate(`/profile/${userInfo.id}`)}
              >
                Vai al mio profilo
              </Button>
            </FormControl>
          </>
        )}
      </Box>
    </>
  );
}
