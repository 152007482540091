import {
  ATT_LOAD_LIST_FAIL,
  ATT_LOAD_LIST_REQUEST,
  ATT_LOAD_LIST_SUCCESS,
  ATT_MODIFY_PRESENCE_FAIL,
  ATT_MODIFY_PRESENCE_REQUEST,
  ATT_MODIFY_PRESENCE_SUCCESS,
} from "../constants/attConstants";

export const attendeeReducer = (
  state = { attendees: [], latest: [] },
  action
) => {
  switch (action.type) {
    case ATT_LOAD_LIST_REQUEST:
      return { ...state, loading: true, attendees: [], error: undefined };
    case ATT_LOAD_LIST_SUCCESS:
      if (action.payload?.message) {
        return { ...state, loading: false, error: action.payload.message };
      }
      return { ...state, loading: false, attendees: action.payload };
    case ATT_LOAD_LIST_FAIL:
      return { loading: false, error: action.payload };

    case ATT_MODIFY_PRESENCE_REQUEST:
      return { ...state, loading: true, error: undefined };
    case ATT_MODIFY_PRESENCE_SUCCESS:
      if (action.payload?.message) {
        return { ...state, loading: false, error: action.payload.message };
      }

      let newattendes = [...state.attendees];
      action.payload.forEach((attnew) => {
        const attendee = newattendes.find(
          (att) => att.when === attnew.when && att.user.id === attnew.user.id
        );
        if (attendee !== undefined) {
          attendee.present = attnew.present;
        } else {
          newattendes.push(attnew);
        }
      });

      return { ...state, loading: false, attendees: newattendes };
    case ATT_MODIFY_PRESENCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
