export const PARAMS_LOAD_REQUEST = 'PARAMS_LOAD_REQUEST';
export const PARAMS_LOAD_SUCCESS = 'PARAMS_LOAD_SUCCESS';
export const PARAMS_LOAD_FAIL = 'PARAMS_LOAD_FAIL';

export const PARAMS_SET_DISCIPLINE = 'PARAMS_SET_DISCIPLINE';

export const PARAMS_SAVE_REQUEST = 'PARAMS_SAVE_REQUEST';
export const PARAMS_SAVE_SUCCESS = 'PARAMS_SAVE_SUCCESS';
export const PARAMS_SAVE_FAIL = 'PARAMS_SAVE_FAIL';

export const CATEGORIES_LOAD_REQUEST = 'CATEGORIES_LOAD_REQUEST';
export const CATEGORIES_LOAD_SUCCESS = 'CATEGORIES_LOAD_SUCCESS';
export const CATEGORIES_LOAD_FAIL = 'CATEGORIES_LOAD_FAIL';

export const CATEGORIES_SAVE_REQUEST = 'CATEGORIES_SAVE_REQUEST';
export const CATEGORIES_SAVE_SUCCESS = 'CATEGORIES_SAVE_SUCCESS';
export const CATEGORIES_SAVE_FAIL = 'CATEGORIES_SAVE_FAIL';
