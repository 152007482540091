import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from "@mui/material";
import React, { useState } from "react";

export const CleanupDialog = ({ dlgOpen, onConfirm }) => {
  const [actions, setActions] = useState([
    { label: "Utenti non attivi", id: "deleteNotActiveUsers", value: false },
    {
      label: "Iscrizioni e pagamenti anni precedenti",
      id: "deleteOldSignups",
      value: false,
    },
    { label: "Fogli presenze", id: "deleteAttendees", value: false },
    { label: "Documenti e Bonifici cancellati", id: "deleteOldDocuments", value: false },
    { label: "Azioni e Sessioni", id: "deleteLogsAndSessions", value: false },
  ]);

  function updateActions(id, value) {
    const newActions = actions.map((act) => {
      return {
        ...act,
        value: act.id === id ? value : act.value,
      };
    });

    setActions(newActions);
  }

  return (
    <Dialog
      open={dlgOpen}
      onClose={() => onConfirm(false, null)}
      maxWidth={"md"}
    >
      <DialogTitle>Pulisci Database</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 3, fontWeight: "bold" }}>
          Attenzione: questa operazione cancellerà i dati del database in modo
          irreversibile, assicurarsi di aver prima fatto un backup
        </Box>
        <Stack direction="column">
          {actions?.map((action) => (
            <React.Fragment key={action.id}>
              <FormControlLabel
                label={action.label}
                sx={{ ml: 1 }}
                control={
                  <Checkbox
                    checked={action.value}
                    onChange={(event) => {
                      updateActions(action.id, event.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            </React.Fragment>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onConfirm(false, false, null)}>Annulla</Button>
        <Button variant="contained" color="success" onClick={() => onConfirm(false, true, actions)}>Preview</Button>
        <Button variant="contained" color="error" onClick={() => onConfirm(true, false, actions)}>
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};
