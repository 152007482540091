import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export const ChangePasswordDialog = ({ dlgOpen, pwdUser, onConfirm }) => {
  const [pwd, setPwd] = useState({});

  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [pwdMessage, setPwdMessage] = useState("");

  useEffect(() => {
    setPwd(pwdUser);
  }, [pwdUser]);

  const checkPassword = () => {
    setPwdMessage("");

    if (password !== password1 || password.length < 3) {
      setPwdMessage("Password troppo corta o non coincidenti");
      return;
    }

    onConfirm(true, password);
  };

  return (
    <Dialog
      open={dlgOpen}
      onClose={() => onConfirm(false, null)}
      maxWidth={"md"}
    >
      <DialogTitle>
        Modifica password per{" "}
        {`${pwd?.name} ${pwd?.surname} (${pwd?.nickname})`}
      </DialogTitle>
      <DialogContent>
        {pwdMessage !== "" && <Alert severity="error">{pwdMessage}</Alert>}
        <Grid container spacing={2} padding={2}>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <TextField
                id="password"
                label="Password"
                type="password"
                value={password}
                variant="standard"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <TextField
                id="password1"
                label="Ripeti password"
                type="password"
                value={password1}
                variant="standard"
                onChange={(e) => setPassword1(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onConfirm(false, null)}>Annulla</Button>
        <Button onClick={() => checkPassword()}>Salva Password</Button>
      </DialogActions>
    </Dialog>
  );
};
