import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function ModifyButtons ({locked, lockedby, onEditUser, onUpdateProfile, onUnlockProfile, children}) {
    return (
      <>
        {lockedby !== null && (
          <Grid item xs={12}>
            <Typography>Modifiche in corso da parte di {lockedby}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="contained"
              color={locked ? "primary" : "warning"}
              disabled={lockedby !== null}
              onClick={onEditUser}
            >
              {locked ? "Modifica" : "Annulla Modifiche"}
            </Button>
            <Button
              variant="contained"
              color="success"
              disabled={locked}
              onClick={onUpdateProfile}
            >
              <i className="fa fa-check-square" />
              &nbsp;Conferma Modifiche
            </Button>
            {lockedby !== null && (
              <Button
                variant="contained"
                color="error"
                onClick={onUnlockProfile}
              >
                Sblocca modifiche
              </Button>
            )}
            {children}
          </Stack>
        </Grid>
      </>
    );
  };
