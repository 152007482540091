import {
  USER_LOADPROFILE_AUTHERROR,
  USER_LOADPROFILE_FAIL,
  USER_LOADPROFILE_REQUEST,
  USER_LOADPROFILE_RESET,
  USER_LOADPROFILE_SUCCESS,
  USER_SET_STATE,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_UPDATE,
  USER_SIGNOUT,
  USER_UPDATE_ACL_REQUEST,
  USER_UPDATE_DATA_REQUEST,
  USER_UPDATE_DOCUMENTS_REQUEST,
  USER_UPDATE_ENABLED_REQUEST,
  USER_UPDATE_FAIL,
  USER_UPDATE_LOCKED_REQUEST,
  USER_UPDATE_PARENT_REQUEST,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PAYMENTS_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_TRANSFER_REQUEST,
} from "../constants/userConstants";

const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
  userProfile: undefined,
};

export const userSigninReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
      return { loading: false, userInfo: action.payload };
    case USER_SIGNIN_FAIL:
      localStorage.removeItem("userInfo");
      return { loading: false, error: action.payload };
    case USER_SIGNIN_UPDATE:
      if (action.payload.id === state.userInfo.id) {
        const update = { ...state.userInfo, acl: action.payload.acl };
        localStorage.setItem("userInfo", JSON.stringify(update));
        return { ...state, userInfo: update, userState: undefined };
      }
      return state;
    case USER_SIGNOUT:
      localStorage.removeItem("userInfo");
      localStorage.removeItem("transferInfo");
      return {};
    case USER_SET_STATE:
      return { ...state, userState: action.payload };
    default:
      return state;
  }
};

export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOADPROFILE_RESET:
      return { loading: false, authError: false, userProfile: undefined };
    case USER_LOADPROFILE_REQUEST:
      return { ...state, authError: false, loading: true };
    case USER_LOADPROFILE_SUCCESS:
      return { loading: false, authError: false, userProfile: action.payload };
    case USER_LOADPROFILE_AUTHERROR:
      return { loading: false, authError: true, userProfile: undefined };
    case USER_LOADPROFILE_FAIL:
      return { loading: false, authError: false, error: action.payload };
    case USER_UPDATE_PASSWORD_REQUEST:
    case USER_UPDATE_LOCKED_REQUEST:
    case USER_UPDATE_ENABLED_REQUEST:
    case USER_UPDATE_PARENT_REQUEST:
    case USER_UPDATE_PAYMENTS_REQUEST:
    case USER_UPDATE_DOCUMENTS_REQUEST:
    case USER_UPDATE_TRANSFER_REQUEST:
    case USER_UPDATE_DATA_REQUEST:
    case USER_UPDATE_ACL_REQUEST:
      return {
        ...state,
        message: null,
        error: null,
        authError: false,
        loading: true,
      };
    case USER_UPDATE_SUCCESS:
      // update profile
      if (action.payload?.user !== undefined)
        return {
          loading: false,
          message: action.payload.flagOnly ? null : "Dati Aggiornati",
          authError: false,
          userProfile: action.payload.user,
        };
      // only update password
      else
        return {
          ...state,
          error: null,
          message: "Password Aggiornata",
          loading: false,
        };
    case USER_UPDATE_FAIL:
      return { ...state, loading: false, message: null, error: action.payload };
    default:
      return state;
  }
};
