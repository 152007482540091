import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Send from "@mui/icons-material/Send";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../actions/userActions";
import { sendTokenMail } from "../utils/user";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function LostPasswordScreen() {
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [tmpID, setTmpID] = useState("");

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { loading: signLoading, error: signError, userInfo } = userSignin;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const submitHandler = (event) => {
    event.preventDefault();

    dispatch(signin(userName, "", tmpID));
  };

  const handleSendToken = async () => {
    setLoading(true);
    setMessage("");
    setErrorMessage("");
    const reply = await sendTokenMail(email);
    setLoading(false);
    if (reply?.error) setErrorMessage(reply.error);
    else setMessage(reply.message);
  };

  useEffect(() => {
    if (userInfo?.id !== undefined) {
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    const s_userName = searchParams.get("userName");
    const s_tmpID = searchParams.get("tmpID");

    if (s_userName && s_tmpID) {
      dispatch(signin(s_userName, "", s_tmpID));
    } else {
      if (s_userName) {
        setUserName(s_userName);
      }
      if (s_tmpID) {
        setTmpID(s_tmpID);
      }
    }
  }, [searchParams, dispatch]);

  return (
    <>
      {(loading || signLoading) && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {signError && <Alert severity="error">{signError}</Alert>}
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography textAlign="center" variant="h5">
            Inserisci l'indirizzo email con cui hai effettuato la registrazione e clicca sull'icona.
          </Typography>
          <Typography textAlign="center" variant="h5">
            Ti sarà recapitata una mail con le istruzioni per l'accesso ed il token.
          </Typography>
          <Typography sx={{ mb: 2 }} textAlign="center" variant="h5">
            Inserendo i dati ricevuti nei campi Nome utente e token potrai rientrare nel tuo profilo.
          </Typography>
        </Box>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="email">e-Mail</InputLabel>
          <OutlinedInput
            id="email"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            label="E-Mail"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSendToken}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-username">
            Nome utente
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-username"
            type="text"
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
            label="Nome utente"
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="token">Token</InputLabel>
          <OutlinedInput
            id="token"
            type="text"
            value={tmpID}
            onChange={(event) => setTmpID(event.target.value)}
            label="Token"
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <Button variant="contained" onClick={submitHandler}>
            Entra con Token
          </Button>
        </FormControl>
      </Box>
    </>
  );
}
