import axiosInstance, { manageReason } from "./axios";

import {
  USERS_LOADPROFILESPARTIAL_SUCCESS,
  USERS_LOADPROFILES_FAIL,
  USERS_LOADPROFILES_REQUEST,
  USERS_LOADPROFILES_SUCCESS,
} from "../constants/usersConstants";

import { signout } from "./userActions";

export const loadUsers =
  (complete = false) =>
  async (dispatch, getState) => {
    let limit = "";
    if (!complete) {
      limit = "?limit=25";
    }
    dispatch({ type: USERS_LOADPROFILES_REQUEST, payload: {} });
    axiosInstance
      .get("/users/userslist.php" + limit)
      .then((response) => {
        if (response === undefined) {
          dispatch(signout());
        } else {
          if (!complete) {
            dispatch({
              type: USERS_LOADPROFILESPARTIAL_SUCCESS,
              payload: response.data,
            });
            dispatch(loadUsers({ complete: true }));
          } else {
            dispatch({
              type: USERS_LOADPROFILES_SUCCESS,
              payload: response.data,
            });
          }
        }
      })
      .catch((reason) => {
        const { message, exit } = manageReason(reason);
        if (exit) {
          dispatch(signout());
        } else {
          dispatch({
            type: USERS_LOADPROFILES_FAIL,
            payload: message,
          });
        }
      });
  };
