import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { loadParams, setDiscipline } from "../actions/paramActions";
import { loadAllAttendees } from "../actions/attActions";
import { loadUsers } from "../actions/usersActions";
import { unloadProfile } from "../actions/userActions";

export default function AttendeesSummaryScreen() {
  const [myfilter, setMyfilter] = useState({
    discipline: "",
    location: "",
    group: "",
    startdate: moment().startOf("month").toDate(),
    enddate: moment().endOf("month").toDate(),
    mstartdate: moment().startOf("month"),
    menddate: moment().endOf("month"),
  });
  const [attendeeColumns, setAttendeeColumns] = useState([]);
  const [mattendees, setMattendees] = useState([]);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const paramList = useSelector((state) => state.paramList);
  const { locations, disciplines, groupNames } = paramList;
  const usersList = useSelector((state) => state.usersList);
  const { userProfiles } = usersList;

  const attendee = useSelector((state) => state.attendee);
  const { loading, message, error, attendees } = attendee;

  const dispatch = useDispatch();

  const changeDiscipline = useCallback(
    (disc) => {
      dispatch(setDiscipline(disc));
      dispatch(loadAllAttendees(disc));
      setMyfilter((oldfilter) => {
        return { ...oldfilter, discipline: disc, group: "" };
      });
    },
    [dispatch]
  );

  function renderAttendeePresence(value) {
    return (
      <>
        {value !== undefined ? (
          value ? (
            <CheckBoxIcon />
          ) : (
            <CheckBoxOutlineBlankIcon />
          )
        ) : null}
      </>
    );
  }

  useEffect(() => {
    let cols = [
      {
        name: "groupname",
        label: "Gruppo",
      },
      {
        name: "surname",
        label: "Cognome",
      },
      {
        name: "name",
        label: "Nome",
      },
    ];

    if (attendees?.length) {
      let ma = [];

      attendees.forEach((att) => {
        const mwhen = moment(att.when, "DD/MM/YYYY").startOf("day");
        if (
          (myfilter.location === "" ||
            groupNames[att.group].location === myfilter.location) &&
          (myfilter.group === "" || att.group === myfilter.group) &&
          (mwhen.isAfter(myfilter.mstartdate) ||
            mwhen.isSame(myfilter.mstartdate)) &&
          (mwhen.isBefore(myfilter.menddate) || mwhen.isSame(myfilter.menddate))
        ) {
          const dd = moment(att.when, "DD/MM/YYYY").format("DD/MM");

          let cindex = cols.findIndex((c) => c.label === dd);
          if (cindex < 0) {
            cols.push({
              name: dd,
              label: dd,
              options: { customBodyRender: renderAttendeePresence },
            });
          }

          let mindex = ma.findIndex((matt) => matt.user.id === att.user.id);
          if (mindex < 0) {
            ma.push({
              ...att,
              groupname: groupNames[att.group]?.name,
              surname: att.user.surname,
              name: att.user.name,
              [dd]: att.present,
            });
          } else {
            ma[mindex] = {
              ...ma[mindex],
              [dd]: att.present,
            };
          }
        }
      });

      setMattendees(ma);
    }

    setAttendeeColumns(cols);
  }, [attendees, groupNames, myfilter]);

  useEffect(() => {
    dispatch(unloadProfile());
    if (userInfo?.aclDiscipline) {
      changeDiscipline(userInfo.aclDiscipline[0]);
    }
  }, [userInfo, changeDiscipline, dispatch]);

  useEffect(() => {
    if (!disciplines?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, disciplines]);

  useEffect(() => {
    if (!userProfiles?.length) dispatch(loadUsers());
  }, [dispatch, userProfiles]);

  const useStyles = makeStyles({
    // entire
    alltable: {
      "& td": { fontSize: "0.9rem", padding: 1 },
    },
  });

  const classes = useStyles();

  function allTableProps() {
    return {
      className: classes.alltable,
    };
  }

  return (
    <>
      {loading && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <>
        {disciplines && userInfo?.aclDiscipline?.length > 0 && (
          <Grid container spacing={1} padding={1} mt={2}>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="discipline">Sport</InputLabel>
                <Select
                  id="discipline"
                  value={myfilter.discipline}
                  onChange={(e) => changeDiscipline(e.target.value)}
                >
                  {disciplines
                    ?.filter((disc) =>
                      userInfo?.aclDiscipline.includes(disc.id)
                    )
                    .map((disc) => (
                      <MenuItem key={disc.id} value={disc.id}>
                        {disc.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="location">Campo Sportivo</InputLabel>
                <Select
                  id="location"
                  value={myfilter.location}
                  onChange={(e) =>
                    setMyfilter({
                      ...myfilter,
                      location: e.target.value,
                      group: "",
                    })
                  }
                >
                  {locations.map((loc) => (
                    <MenuItem key={loc.id} value={loc.id}>
                      {loc.name}
                    </MenuItem>
                  ))}
                  <MenuItem value={""}>Tutti</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="group">Gruppo</InputLabel>
                <Select
                  id="group"
                  value={myfilter.group}
                  onChange={(e) =>
                    setMyfilter({
                      ...myfilter,
                      group: e.target.value,
                    })
                  }
                >
                  {Object.entries(groupNames)
                    ?.filter(
                      ([key, value]) => value.location === myfilter.location
                    )
                    .map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value.name}
                      </MenuItem>
                    ))}
                  <MenuItem value={""}>Tutti</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  id="date"
                  label="Data Inizio"
                  type="text"
                  value={myfilter.startdate}
                  onChange={(value) =>
                    setMyfilter({
                      ...myfilter,
                      startdate: value,
                      mstartdate: moment(value),
                    })
                  }
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  id="date"
                  label="Data Fine"
                  type="text"
                  value={myfilter.enddate}
                  onChange={(value) =>
                    setMyfilter({
                      ...myfilter,
                      enddate: value,
                      menddate: moment(value),
                    })
                  }
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}

        {/* riepilogo fogli presenze */}
        {groupNames && (
          <>
            {attendees?.length === 0 && <Box>Nessun Dato</Box>}
            {attendees?.length > 0 && (
              <Box margin={2} flex={true} flexGrow="1">
                <MUIDataTable
                  title="Riepilogo Presenze"
                  data={mattendees}
                  columns={attendeeColumns}
                  options={{
                    selectableRowsHideCheckboxes: true,
                    setTableProps: allTableProps,
                    download: true,
                    print: true,
                    viewColumns: true,
                    filter: true,
                    search: true,
                    sort: true,
                  }}
                />
              </Box>
            )}
          </>
        )}
      </>
    </>
  );
}
