import axios from "axios";
import { apiReference, baseApiUrl } from "../utils/config.js";

const axiosBase = axios.create({
  baseURL: baseApiUrl,
  headers: {
    "Content-Type": "application/json",
    Reference: apiReference,
    "Running-Mode":
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVMODEKEY
        : "production",
  },
});

const axiosInstance = axios.create({
  baseURL: baseApiUrl,
  headers: {
    "Content-Type": "application/json",
    Reference: apiReference,
    "Running-Mode":
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVMODEKEY
        : "production",
  },
  withCredentials: true,
});

let accessToken = undefined;
const setAccessToken = (token) => {
  accessToken = token;
};

async function refresh() {
  try {
    const { data } = await axiosInstance.get("/users/refreshtoken.php");
    if (data?.access_token) setAccessToken(data.access_token);

    return data?.access_token;
  } catch (error) {
    console.error(error);
  }

  return undefined;
}

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers["Authorization"] && accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      //console.log("access token expired?", prevRequest);
      prevRequest.sent = true;
      const accessToken = await refresh();
      //console.log("result", accessToken);
      if (accessToken) {
        prevRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        return axiosInstance(prevRequest);
      }
    }
    return Promise.reject(error);
  }
);

function manageReason(reason) {
  if (reason.response?.status === 401) {
    return {
      exit: true,
      message: "Utente o password errati (o sessione scaduta)",
    };
  }

  let message =
    reason.response && reason.response.data.message
      ? reason.response.data.message
      : reason.message;

  if (reason.response?.status === 402) {
    message = "Richiesta non conforme";
  }

  if (reason.response?.status === 403) {
    message = "Accesso negato";
  }

  return {
    exit: false,
    message,
  };
}

export { axiosBase, setAccessToken, manageReason, refresh };

export default axiosInstance;
