import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  loadProfile,
  setNewParentPassword,
  setParentNickname,
  setlocked,
  updateparent,
} from "../actions/userActions";
import ModifyButtons from "../components/ModifyButtons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { checkNicknameExists } from "../utils/user";
import { Person } from "@mui/icons-material";

export default function ParentScreen() {
  const [curId, setCurId] = useState(0);
  const [parIndex, setParIndex] = useState(0);

  const [modParent, setModParent] = useState(null);

  const [nickname, setNickname] = useState("");
  const [nickNameError, setNickNameError] = useState(false);

  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [pwdMessage, setPwdMessage] = useState("");

  const [locked, setLocked] = useState(true);
  const [lockedby, setLockedby] = useState(null);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  let params = useParams();

  const userDetails = useSelector((state) => state.userDetails);
  const { userProfile, loading, error, message } = userDetails;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const restoreUserProfileState = useCallback(() => {
    setModParent({ ...userProfile.parents[parIndex] });
  }, [userProfile, parIndex]);

  useEffect(() => {
    let newId =
      params && params.id
        ? params.id
        : userInfo.isParent
        ? userInfo.childid
        : userInfo.id;
    setCurId(newId);

    const parId = params && params.parid;
    if (userProfile && userProfile.parents?.length > 0) {
      let idx = userProfile.parents.findIndex((parent) => parent.id === parId);
      setParIndex(idx);
    }
  }, [userInfo, userProfile, params]);

  useEffect(() => {
    if (curId !== 0 && (!userProfile || userProfile.id !== curId)) {
      dispatch(loadProfile(curId));
    }

    if (userProfile && userProfile.id === curId) {
      restoreUserProfileState();

      setPassword("");
      setPassword1("");

      setLocked(userProfile.locked);

      setLockedby(
        userProfile.lockedbyusr != null ? userProfile.lockedbyusr : null
      );
    }
  }, [userInfo, userProfile, dispatch, curId, restoreUserProfileState]);

  const onEditUser = (event) => {
    event.preventDefault();

    if (!locked) {
      // restore data
      restoreUserProfileState();
    }

    dispatch(setlocked(userProfile.id, locked));
  };

  const onUnlockProfile = () => {
    dispatch(setlocked(userProfile.id, false));
    //restoreUserProfileState();
  };

  const onUpdateParent = (event) => {
    const valid = event.currentTarget.form.reportValidity();
    if (!valid) return;

    const newParent = {
      parId: params.parid,
      ...modParent,
    };

    dispatch(updateparent(userProfile.id, newParent));
  };

  const onChangeParentParam = (param, value, type = "text") => {
    var val = value;
    if (type === "checkbox") {
      val = value ? 1 : 0;
    }
    setModParent({ ...modParent, [param]: val });
  };

  async function checkExists() {
    if (!nickname) {
      setNickNameError(false);
      return false;
    }

    const exists = await checkNicknameExists(nickname);

    setNickNameError(exists);

    return exists;
  }

  const onSetNickname = async (event) => {
    event.preventDefault();

    if (!nickname) {
      return;
    }

    if (await checkExists()) return;

    dispatch(setParentNickname(userProfile.id, modParent.id, nickname));
  };

  const onChangePassword = (event) => {
    event.preventDefault();

    if (password !== password1 || password.length < 3) {
      setPwdMessage("Password troppo corta o non coincidenti");
      return;
    }

    dispatch(setNewParentPassword(modParent.id, password));
    setPassword("");
    setPassword1("");
  };

  //TODO: componente
  const PasswordAlertDialog = () => {
    return (
      <Dialog
        open={pwdMessage !== ""}
        onClose={() => setPwdMessage("")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Dati non validi</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {pwdMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPwdMessage("")} autoFocus>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {loading && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <PasswordAlertDialog />
      {modParent && (
        <Box component="form" autoComplete="off">
          <Grid container spacing={2} padding={2}>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label="Cognome"
                  value={userProfile.surname}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label="Nome"
                  value={userProfile.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label="Data di nascita"
                  value={userProfile.birthdate}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label="E-mail"
                  value={userProfile.email}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label="Cellulare"
                  value={userProfile.phonenr}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label="Telefono casa"
                  value={userProfile.homenr}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>

            <ModifyButtons
              locked={locked}
              lockedby={lockedby}
              onEditUser={onEditUser}
              onUnlockProfile={onUnlockProfile}
              onUpdateProfile={onUpdateParent}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/profile/${userProfile.id}`)}
              >
                Dati atleta
              </Button>
            </ModifyButtons>

            <Grid item xs={12}>
              <Typography variant="h5">Dati genitore</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id="surname"
                  label="Cognome"
                  type="text"
                  value={modParent.surname}
                  variant="standard"
                  disabled={locked}
                  onChange={(e) =>
                    onChangeParentParam("surname", e.target.value)
                  }
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id="name"
                  label="Nome"
                  type="text"
                  value={modParent.name}
                  variant="standard"
                  disabled={locked}
                  onChange={(e) => onChangeParentParam("name", e.target.value)}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="category">Parentela</InputLabel>
                <Select
                  id="category"
                  label="Parentela"
                  value={modParent.link}
                  disabled={locked}
                  onChange={(e) => onChangeParentParam("link", e.target.value)}
                  required
                >
                  {["Padre", "Madre", "Tutore"].map((opt) => (
                    <MenuItem key={opt} value={opt}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id="email"
                  label="e-Mail"
                  type="email"
                  value={modParent.email}
                  variant="standard"
                  disabled={locked}
                  onChange={(e) => onChangeParentParam("email", e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id="cf"
                  label="Codice Fiscale"
                  type="text"
                  value={modParent.cf}
                  variant="standard"
                  disabled={locked}
                  onChange={(e) => onChangeParentParam("cf", e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id="phonenr"
                  label="Cellulare"
                  type="text"
                  value={modParent.phonenr}
                  variant="standard"
                  disabled={locked}
                  onChange={(e) =>
                    onChangeParentParam("phonenr", e.target.value)
                  }
                  required
                />
              </FormControl>
            </Grid>

            {modParent?.nickname ? (
              <Grid item xs={6} md={3}>
                <FormControl fullWidth>
                  <TextField
                    id="username"
                    label="Username"
                    value={modParent.nickname}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Grid>
            ) : (
              <>
                <Grid item xs={4} md={3}>
                  Completa la registrazione scegliendo un nome utente
                </Grid>
                <Grid item xs={4} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      id="nickname"
                      label="Username"
                      value={nickname}
                      variant="standard"
                      error={nickNameError}
                      helperText={
                        nickNameError ? "Username non disponibile" : ""
                      }
                      onChange={(e) => setNickname(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => checkExists()}>
                              <Person />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="success"
                    disabled={!nickname}
                    onClick={onSetNickname}
                  >
                    Imposta Nome Utente
                  </Button>
                </Grid>{" "}
              </>
            )}

            <Grid item xs={12}>
              <Typography variant="h5">Modifica password</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  value={password}
                  variant="standard"
                  disabled={!modParent?.nickname}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  id="password1"
                  label="Ripeti password"
                  type="password"
                  value={password1}
                  variant="standard"
                  disabled={!modParent?.nickname}
                  onChange={(e) => setPassword1(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="success"
                disabled={!modParent?.nickname}
                onClick={onChangePassword}
              >
                Aggiorna Password
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
