import {
  TRANSFER_IMPORT_FAIL,
  TRANSFER_IMPORT_REQUEST,
  TRANSFER_IMPORT_SUCCESS,
  TRANSFER_LOADUSERS_FAIL,
  TRANSFER_LOADUSERS_REQUEST,
  TRANSFER_LOADUSERS_SUCCESS,
  TRANSFER_SIGNIN_FAIL,
  TRANSFER_SIGNIN_REQUEST,
  TRANSFER_SIGNIN_SUCCESS,
} from "../constants/transferConstants";
import { USER_LOADPROFILE_SUCCESS } from "../constants/userConstants";
import axiosInstance, { manageReason } from "./axios";
import axiosTransferInstance, {
  getTransferAuthToken,
  setTransferAuthToken,
} from "./axiostransfer";
import {
  baseTransferApiUrl,
  baseTransferFileUrl,
  transferApiReference,
} from "../utils/config";
import { signout } from "./userActions";

export const transfersignin = (userName, password) => async (dispatch) => {
  dispatch({ type: TRANSFER_SIGNIN_REQUEST, payload: { userName, password } });
  try {
    const { data } = await axiosTransferInstance.post("/users/signin.php", {
      userName,
      password,
    });

    dispatch({ type: TRANSFER_SIGNIN_SUCCESS, payload: data });
    setTransferAuthToken(data.access_token);
    dispatch(loadTransfers());
  } catch (error) {
    dispatch({
      type: TRANSFER_SIGNIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const loadTransfers = () => async (dispatch) => {
  dispatch({ type: TRANSFER_LOADUSERS_REQUEST, payload: {} });
  axiosTransferInstance
    .get("/users/userslist.php?transfer=1")
    .then((response) => {
      if (response !== undefined) {
        dispatch({ type: TRANSFER_LOADUSERS_SUCCESS, payload: response.data });
      }
    })
    .catch((reason) => {
      dispatch({
        type: TRANSFER_LOADUSERS_FAIL,
        payload:
          reason.response && reason.response.data.message
            ? reason.response.data.message
            : reason.message,
      });
    });
};

// export const signout = () => async (dispatch) => {
//   try {
//     await axiosTransferInstance.get('/users/signout.php');
//   } catch (error) {
//     // nothing relevant to do
//   }
//   dispatch({ type: TRANSFER_SIGNOUT });
// };

// export const transferconfirm = (userId) => async (dispatch) => {
//   dispatch({ type: TRANSFER_CONFIRM_REQUEST, payload: { userId } });
//   axiosTransferInstance
//     .post("/users/updatetransfer.php", { userId, transfer: "TRANSFERED" })
//     .then((response) => {
//       if (response !== undefined) {
//         dispatch({
//           type: TRANSFER_CONFIRM_SUCCESS,
//           payload: { user: response.data, flagOnly: false },
//         });
//       }
//     })
//     .catch((reason) => {
//       dispatch({
//         type: TRANSFER_CONFIRM_FAIL,
//         payload:
//           reason.response && reason.response.data.message
//             ? reason.response.data.message
//             : reason.message,
//       });
//     });
// };

export const importUser =
  (userId, nickname, category, location) => async (dispatch) => {
    dispatch({
      type: TRANSFER_IMPORT_REQUEST,
      payload: { userId, nickname, category, location },
    });

    // NOTA axios locale perche' si fa backend to backend
    axiosInstance
      .post("/users/transfer.php", {
        apiUri: baseTransferApiUrl,
        fileUri: baseTransferFileUrl,
        usrId: userId,
        reference: transferApiReference,
        category,
        location,
        nickname,
        authToken: getTransferAuthToken(),
      })
      .then((response) => {
        if (response !== undefined) {
          if (response.data.error) {
            dispatch({
              type: TRANSFER_IMPORT_FAIL,
              payload: response.data,
            });
          } else {
            dispatch({
              type: TRANSFER_IMPORT_SUCCESS,
              payload: response.data,
            });
            dispatch({
              type: USER_LOADPROFILE_SUCCESS,
              payload: response.data.user,
            });
            dispatch(loadTransfers());
          }
        }
      })
      .catch((reason) => {
        const { message, exit } = manageReason(reason);
        if (exit) {
          dispatch(signout());
        } else {
          dispatch({
            type: TRANSFER_IMPORT_FAIL,
            payload: message,
          });
        }
      });
  };
