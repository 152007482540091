import { Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { getSessions } from "../utils/utils";
import { signout, unloadProfile } from "../actions/userActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  deftable: {
    "& td": { fontSize: "0.8rem", paddingTop: 0, paddingBottom: 0 },
  },
  expired: {
    "& td": { backgroundColor: "#AAA" },
  },
});

export default function SessionsScreen() {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const dispatch = useDispatch();

  function tableProps() {
    return {
      className: classes.deftable,
    };
  }

  function rowProps(row, dataIndex, rowIndex) {
    const session = sessions[dataIndex];

    return {
      className: clsx({
        [classes.expired]: !session.valid,
      }),
    };
  }

  const sessionsColumns = [
    {
      name: "userId",
      label: "Id Utente",
    },
    { name: "nickname", label: "Nickname Utente" },
    {
      name: "expireAt",
      label: "Scadenza",
    },
  ];

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const s = await getSessions();
      if (s?.error === undefined) {
        setSessions(s);
        setLoading(false);
      } else {
        console.log("cannot get sessions", s);
        if (s.exit) dispatch(signout());
      }
    }

    dispatch(unloadProfile());
    if (!sessions?.length) {
      fetch();
    }
  }, [sessions, dispatch]);

  return (
    <>
      {loading && <LinearProgress />}
      <>
        {sessions?.length > 0 && (
          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              title="Sessioni"
              data={sessions}
              columns={sessionsColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                viewColumns: false,
                filter: false,
                rowsPerPage: 100,
                setTableProps: tableProps,
                setRowProps: rowProps,
              }}
            />
          </Box>
        )}
      </>
    </>
  );
}
