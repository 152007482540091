import axiosInstance, {
  axiosBase,
  manageReason,
  setAccessToken,
} from "./axios";

import {
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_LOADPROFILE_FAIL,
  USER_LOADPROFILE_REQUEST,
  USER_LOADPROFILE_SUCCESS,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_LOCKED_REQUEST,
  USER_UPDATE_DATA_REQUEST,
  USER_LOADPROFILE_RESET,
  USER_UPDATE_ENABLED_REQUEST,
  USER_DELETE_REQUEST,
  USER_UPDATE_PARENT_REQUEST,
  USER_UPDATE_PAYMENTS_REQUEST,
  USER_UPDATE_DOCUMENTS_REQUEST,
  USER_UPDATE_TRANSFER_REQUEST,
  USER_UPDATE_ACL_REQUEST,
  USER_SIGNIN_UPDATE,
  USER_LOADPROFILE_AUTHERROR,
  USER_SET_STATE,
} from "../constants/userConstants";
import {
  USERS_DELETE_USER,
  USERS_FAIL_USERDATA,
  USERS_REQUEST_USERDATA,
  USERS_UPDATE_USERDATA,
} from "../constants/usersConstants";
import { DOCS_FORCE_RELOAD } from "../constants/docConstants";
import { publicName } from "../utils/config";
import { getEmailAdmin } from "../utils/utils";

export const signin = (userName, password, tmpID) => async (dispatch) => {
  dispatch({
    type: USER_SIGNIN_REQUEST,
    payload: { userName, password, tmpID },
  });
  try {
    const { data } = await axiosBase.post("/users/signin.php", {
      userName,
      password,
      tmpID,
    });

    const userInfo = { ...data, access_token: undefined };
    dispatch({
      type: USER_SIGNIN_SUCCESS,
      payload: { ...userInfo, tmpID: true },
    });
    setAccessToken(data.access_token);
  } catch (reason) {
    const { message } = manageReason(reason);
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload: message,
    });
  }
};

export const signout = () => async (dispatch) => {
  try {
    await axiosBase.get("/users/signout.php");
  } catch (error) {
    // nothing relevant to do
  }
  dispatch({ type: USER_SIGNOUT });
  dispatch({ type: USER_LOADPROFILE_RESET });
};

export const loadProfile = (id) => async (dispatch) => {
  dispatch({ type: USER_LOADPROFILE_REQUEST, payload: { id } });
  axiosInstance
    .get("/users/profile.php?usrid=" + id)
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        if (response.data.id === id)
          dispatch({ type: USER_LOADPROFILE_SUCCESS, payload: response.data });
        else
          dispatch({
            type: USER_LOADPROFILE_AUTHERROR,
            payload: response.data,
          });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else if (reason.response?.status === 403) {
        dispatch({
          type: USER_LOADPROFILE_AUTHERROR,
          payload: message,
        });
      } else {
        dispatch({
          type: USER_LOADPROFILE_FAIL,
          payload:
            reason.response && reason.response.data.message
              ? reason.response.data.message
              : reason.message,
        });
      }
    });
};

export const unloadProfile = () => async (dispatch) => {
  dispatch({ type: USER_LOADPROFILE_RESET });
};

export const setNewPassword =
  (userId, password) => async (dispatch, getState) => {
    dispatch({
      type: USER_UPDATE_PASSWORD_REQUEST,
      payload: { userId, password },
    });
    axiosInstance
      .post("/users/changepwd.php", { userId, password })
      .then((response) => {
        dispatch({ type: USER_UPDATE_SUCCESS });
      })
      .catch((reason) => {
        const { message, exit } = manageReason(reason);
        if (exit) {
          dispatch(signout());
        } else {
          dispatch({
            type: USER_UPDATE_FAIL,
            payload: message,
          });
        }
      });
  };

export const setNewParentPassword =
  (parentId, password) => async (dispatch, getState) => {
    dispatch({
      type: USER_UPDATE_PASSWORD_REQUEST,
      payload: { parentId, password },
    });
    axiosInstance
      .post("/users/changepwd.php", { parentId, password })
      .then((response) => {
        dispatch({ type: USER_UPDATE_SUCCESS });
      })
      .catch((reason) => {
        const { message, exit } = manageReason(reason);
        if (exit) {
          dispatch(signout());
        } else {
          dispatch({
            type: USER_UPDATE_FAIL,
            payload: message,
          });
        }
      });
  };

export const setlocked = (userId, locked) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_LOCKED_REQUEST, payload: { userId, locked } });
  axiosInstance
    .post("/users/setlocked.php", { userId, lock: locked })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: { user: response.data, flagOnly: true },
        });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
      }
    });
};

export const updateprofile = (user) => async (dispatch) => {
  dispatch({ type: USER_UPDATE_DATA_REQUEST, payload: { user } });
  axiosInstance
    .post("/users/updateprofile.php", user)
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: { user: response.data, flagOnly: false },
        });
        dispatch({
          type: USERS_UPDATE_USERDATA,
          payload: { user: response.data, message: "Dati aggiornati" },
        });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
      }
    });
};

export const setUserEnable = (userId, enabled) => async (dispatch) => {
  dispatch({ type: USER_UPDATE_ENABLED_REQUEST, payload: { userId, enabled } });
  dispatch({
    type: USERS_REQUEST_USERDATA,
  });
  axiosInstance
    .post("/users/setenabled.php", { userId, enabled, type: "user" })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USERS_UPDATE_USERDATA,
          payload: { user: response.data, message: "Dati aggiornati" },
        });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
        dispatch({
          type: USERS_FAIL_USERDATA,
          payload: message,
        });
      }
    });
};

export const setUserAlertEnable = (userId, enabled) => async (dispatch) => {
  dispatch({
    type: USER_UPDATE_ENABLED_REQUEST,
    payload: { userId, alertenabled: enabled },
  });
  dispatch({
    type: USERS_REQUEST_USERDATA,
  });
  axiosInstance
    .post("/users/setenabled.php", { userId, enabled, type: "useralert" })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USERS_UPDATE_USERDATA,
          payload: { user: response.data, message: "Dati aggiornati" },
        });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
        dispatch({
          type: USERS_FAIL_USERDATA,
          payload: message,
        });
      }
    });
};

export const sendUserAlert =
  (userId, expired, type, expdate, discipline) => async (dispatch) => {
    dispatch({
      type: USER_UPDATE_DATA_REQUEST,
      payload: {
        userId,
        expired,
        type,
        expdate,
        discipline,
      },
    });
    dispatch({
      type: USERS_REQUEST_USERDATA,
    });
    axiosInstance
      .post("/users/sendalert.php", {
        userId,
        expired,
        type,
        expdate,
        discipline: discipline.id,
        from: publicName,
        emailadmin: getEmailAdmin(discipline),
      })
      .then((response) => {
        if (response === undefined) {
          dispatch(signout());
        } else {
          const { user, summary } = response.data;
          const message = `${
            summary.num_mails
          } mail inviata/e a ${summary.to.join(";")}`;
          dispatch({
            type: USERS_UPDATE_USERDATA,
            payload: {
              user,
              message,
            },
          });
        }
      })
      .catch((reason) => {
        const { message, exit } = manageReason(reason);
        if (exit) {
          dispatch(signout());
        } else {
          dispatch({
            type: USER_UPDATE_FAIL,
            payload: message,
          });
          dispatch({
            type: USERS_FAIL_USERDATA,
            payload: message,
          });
        }
      });
  };

export const setUserAcl = (userId, acl) => async (dispatch) => {
  dispatch({ type: USER_UPDATE_ACL_REQUEST, payload: { userId, acl } });
  dispatch({
    type: USERS_REQUEST_USERDATA,
  });
  axiosInstance
    .post("/users/updateacl.php", { userId, acl })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: { user: response.data, flagOnly: true },
        });
        dispatch({
          type: USERS_UPDATE_USERDATA,
          payload: { user: response.data, message: "Dati aggiornati" },
        });
        dispatch({ type: USER_SIGNIN_UPDATE, payload: response.data });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
        dispatch({
          type: USERS_FAIL_USERDATA,
          payload: message,
        });
      }
    });
};

export const deleteUser = (userId) => async (dispatch) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: { userId } });
  axiosInstance
    .post("/users/delete.php", { userId })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({ type: USERS_DELETE_USER, payload: userId });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
      }
    });
};

export const setparentflag = (userId, type, data) => async (dispatch) => {
  dispatch({
    type: USER_UPDATE_PARENT_REQUEST,
    payload: { userId, type, data },
  });
  axiosInstance
    .post("/users/updateparent.php", { userId, type, ...data })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: { user: response.data, flagOnly: true },
        });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
      }
    });
};

export const setParentNickname =
  (userId, parentId, nickname) => async (dispatch) => {
    dispatch({
      type: USER_UPDATE_PARENT_REQUEST,
      payload: { userId, parentId, nickname },
    });
    axiosInstance
      .post("/users/updateparent.php", {
        userId,
        type: "setnickname",
        nickname,
        parentId,
      })
      .then((response) => {
        if (response === undefined) {
          dispatch(signout());
        } else {
          dispatch({
            type: USER_UPDATE_SUCCESS,
            payload: { user: response.data, flagOnly: false },
          });
        }
      })
      .catch((reason) => {
        const { message, exit } = manageReason(reason);
        if (exit) {
          dispatch(signout());
        } else {
          dispatch({
            type: USER_UPDATE_FAIL,
            payload: message,
          });
        }
      });
  };

export const updateparent = (userId, parent) => async (dispatch) => {
  dispatch({ type: USER_UPDATE_PARENT_REQUEST, payload: { userId, parent } });
  axiosInstance
    .post("/users/updateparent.php", {
      userId,
      type: "update",
      ...parent,
      parentId: parent.id,
    })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: { user: response.data, flagOnly: false },
        });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
      }
    });
};

export const updatesignup = (userId, signup) => async (dispatch) => {
  dispatch({ type: USER_UPDATE_PAYMENTS_REQUEST, payload: { userId, signup } });
  axiosInstance
    .post("/users/updatepayments.php", { type: "signup", ...signup })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: { user: response.data, flagOnly: false },
        });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
      }
    });
};

export const updatepayment = (userId, type, payment) => async (dispatch) => {
  dispatch({
    type: USER_UPDATE_PAYMENTS_REQUEST,
    payload: { userId, type, payment },
  });
  axiosInstance
    .post("/users/updatepayments.php", { userId, type, ...payment })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: { user: response.data, flagOnly: false },
        });
        dispatch({ type: DOCS_FORCE_RELOAD, payload: {} });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
      }
    });
};

export const updatedoc = (userId, type, doc) => async (dispatch) => {
  dispatch({
    type: USER_UPDATE_DOCUMENTS_REQUEST,
    payload: { userId, type, doc },
  });
  axiosInstance
    .post("/users/updatedocs.php", { userId, type, ...doc })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: { user: response.data, flagOnly: false },
        });
        dispatch({ type: DOCS_FORCE_RELOAD, payload: {} });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
      }
    });
};

export const updatetransfer = (userId, transfer) => async (dispatch) => {
  dispatch({
    type: USER_UPDATE_TRANSFER_REQUEST,
    payload: { userId, transfer },
  });
  dispatch({
    type: USERS_REQUEST_USERDATA,
  });
  axiosInstance
    .post("/users/updatetransfer.php", { userId, transfer })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: { user: response.data, flagOnly: false },
        });
        dispatch({
          type: USERS_UPDATE_USERDATA,
          payload: { user: response.data, message: "Dati aggiornati" },
        });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: USER_UPDATE_FAIL,
          payload: message,
        });
        dispatch({
          type: USERS_FAIL_USERDATA,
          payload: message,
        });
      }
    });
};

export const setUserState = (userState) => (dispatch) => {
  dispatch({
    type: USER_SET_STATE,
    payload: userState,
  });
};
