import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadParams,
  setDiscipline,
  updateDocTypes,
} from "../actions/paramActions";
import { Delete } from "@mui/icons-material";
import { unloadProfile } from "../actions/userActions";
import MUIDataTable from "mui-datatables";
import { uploadModule } from "../utils/docs";

export default function DiscipineEditScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const paramList = useSelector((state) => state.paramList);
  const { loading, error, message, disciplines, docTypes } = paramList;

  const [curDiscipline, setCurDiscipline] = useState("");

  const [fileUpload, setFileUpload] = useState({
    docDescription: "",
    docKey: "",
    isInfo: false,
    progress: 0,
    message: "",
    isError: false,
    currentFile: undefined,
  });

  const dispatch = useDispatch();

  function handleDeleteModule(doc) {
    dispatch(updateDocTypes("remove", doc));
    //TODO: rimuovere il file da backend
  }

  const changeDiscipline = useCallback(
    (disc) => {
      dispatch(setDiscipline(disc));
      setCurDiscipline(disc);
    },
    [setCurDiscipline, dispatch]
  );

  useEffect(() => {
    dispatch(unloadProfile());
    if (!disciplines?.length) {
      dispatch(loadParams());
    } else if (!curDiscipline) {
      changeDiscipline(userInfo?.aclDiscipline[0]);
    }
  }, [dispatch, disciplines, curDiscipline, userInfo, changeDiscipline]);

  const useStyles = makeStyles({
    deftable: {
      "& td": { fontSize: "0.9rem", paddingTop: 0, paddingBottom: 0 },
    },
  });

  const classes = useStyles();

  function tableProps() {
    return {
      className: classes.deftable,
    };
  }

  function renderDocTypeActions(value, { rowData }, updateValue) {
    const docType = docTypes.find((dt) => dt.key === rowData[0]);

    return (
      <Stack direction="row" spacing={0.4}>
        {docType?.edit === true && (
          <IconButton
            size="small"
            onClick={() => {
              handleDeleteModule(docType);
            }}
          >
            <Delete />
          </IconButton>
        )}
      </Stack>
    );
  }

  const docTypesColumns = [
    {
      name: "key",
      label: "Chiave",
    },
    { name: "label", label: "Etichetta" },
    {
      name: "target",
      label: "Target",
      options: {
        customBodyRender: (value, { rowData }) => {
          const docType = docTypes.find((dt) => dt.key === rowData[0]);

          if (docType?.filename) return decodeURIComponent(docType.filename);

          return docType.profileurl;
        },
      },
    },
    {
      name: "info",
      label: "Solo download",
      options: {
        customBodyRender: (value) => {
          return value ? "Si" : "No";
        },
      },
    },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderDocTypeActions },
    },
  ];

  const uploadFile = async (event) => {
    const valid = event.currentTarget.form.reportValidity();
    if (!valid) return;

    uploadModule(fileUpload, (event) => {
      setFileUpload({
        ...fileUpload,
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {
        dispatch(
          updateDocTypes("add", {
            key: fileUpload.docKey,
            label: fileUpload.docDescription,
            filename: `${response.data.modulesdir.substring(
              1
            )}/${encodeURIComponent(response.data.filename)}`,
            info: fileUpload.isInfo,
          })
        );

        setFileUpload({
          ...fileUpload,
          progress: 0,
          message: response.data.message,
          isError: false,
        });
      })
      .catch((error) => {
        console.log(error);
        setFileUpload({
          ...fileUpload,
          progress: 0,
          message: "Errore durante caricamento file",
          isError: true,
        });
      });
  };

  return (
    <>
      {loading && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      {disciplines &&
        (userInfo?.aclDiscipline?.length > 0 || userInfo?.isAdmin) && (
          <Box padding={1} sx={{ mt: 2 }}>
            <FormControl variant="standard">
              <Select
                id="discipline"
                value={curDiscipline}
                label="Sport"
                onChange={(e) => changeDiscipline(e.target.value)}
              >
                {disciplines
                  ?.filter(
                    (disc) =>
                      userInfo?.aclDiscipline.includes(disc.id) ||
                      userInfo?.isAdmin
                  )
                  .map((disc) => (
                    <MenuItem key={disc.id} value={disc.id}>
                      {disc.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}

      {fileUpload.message && (
        <Alert
          variant="standard"
          severity={fileUpload.isError ? "error" : "info"}
        >
          {fileUpload.message}
        </Alert>
      )}
      <Box component="form" autoComplete="off">
        <Grid container spacing={2} padding={2}>
          {fileUpload.currentFile && fileUpload.progress > 0 && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress
                    variant="determinate"
                    value={fileUpload.progress}
                  />
                </Box>
                <Box minWidth={35}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >{`${fileUpload.progress}%`}</Typography>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={2} md={2}>
            <FormControl fullWidth>
              <label htmlFor="btn-upload">
                <Input
                  id="btn-upload"
                  type="file"
                  onChange={(event) =>
                    setFileUpload({
                      ...fileUpload,
                      currentFile: event.target.files[0],
                    })
                  }
                  style={{ display: "none" }}
                />
                <Button variant="contained" component="span">
                  Scegli file...
                </Button>
              </label>
            </FormControl>
          </Grid>
          <Grid item xs={2} md={2}>
            <FormControl fullWidth sx={{ margin: "auto" }}>
              <Button
                color="primary"
                variant="contained"
                disabled={!fileUpload.currentFile}
                onClick={uploadFile}
              >
                Carica
              </Button>
            </FormControl>
          </Grid>

          <Grid item xs={4} md={2}>
            <FormControl fullWidth>
              <TextField
                id="dockey"
                label="Chiave"
                type="text"
                value={fileUpload.docKey}
                variant="standard"
                onChange={(event) =>
                  setFileUpload({
                    ...fileUpload,
                    docKey: event.target.value,
                  })
                }
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <TextField
                id="docdesc"
                label="Descrizione"
                type="text"
                value={fileUpload.docDescription}
                variant="standard"
                onChange={(event) =>
                  setFileUpload({
                    ...fileUpload,
                    docDescription: event.target.value,
                  })
                }
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} md={2}>
            <FormControl>
              <FormControlLabel
                label="Solo download"
                sx={{ ml: 1 }}
                control={
                  <Checkbox
                    value={fileUpload.isInfo}
                    onChange={(event) =>
                      setFileUpload({
                        ...fileUpload,
                        isInfo: event.target.checked,
                      })
                    }
                  />
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={10} md={6}>
            <FormControl fullWidth>
              <TextField
                id="filename"
                type="text"
                value={fileUpload.currentFile?.name || "--"}
                InputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {docTypes?.length > 0 && (
        <Box margin={2} flex={true} flexGrow="1">
          <MUIDataTable
            title="Modulistica"
            data={docTypes}
            columns={docTypesColumns}
            options={{
              selectableRowsHideCheckboxes: true,
              viewColumns: true,
              filter: false,
              rowsPerPage: 15,
              setTableProps: tableProps,
            }}
          />
        </Box>
      )}
    </>
  );
}
