import { Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import { getLogs } from "../utils/utils";
import { signout, unloadProfile } from "../actions/userActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  deftable: {
    "& td": { fontSize: "0.8rem", paddingTop: 0, paddingBottom: 0 },
  },
});

export default function LogsScreen() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const dispatch = useDispatch();

  function tableProps() {
    return {
      className: classes.deftable,
    };
  }

  const logsColumns = [
    {
      name: "userId",
      label: "Id Utente",
    },
    { name: "nickname", label: "Nickname Utente" },
    { name: "sql", label: "Query SQL" },
    {
      name: "timestamp",
      label: "Timestamp",
    },
  ];

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const l = await getLogs();
      if (l?.error === undefined) {
        setLogs(l);
        setLoading(false);
      } else {
        console.log("cannot get logs", l);
        if (l.exit) dispatch(signout());
      }
    }

    dispatch(unloadProfile());
    if (!logs?.length) {
      fetch();
    }
  }, [logs, dispatch]);

  return (
    <>
      {loading && <LinearProgress />}
      <>
        {logs?.length > 0 && (
          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              title="Logs"
              data={logs}
              columns={logsColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                viewColumns: false,
                filter: false,
                rowsPerPage: 100,
                setTableProps: tableProps,
              }}
            />
          </Box>
        )}
      </>
    </>
  );
}
