import axios from 'axios';
import { baseTransferApiUrl, transferApiReference } from '../utils/config';

const axiosTransferInstance = axios.create({headers: {'Content-Type': 'application/json'}});

axiosTransferInstance.defaults.baseURL = baseTransferApiUrl;
axiosTransferInstance.defaults.headers.Reference = transferApiReference;

var token = null;

export function setTransferAuthToken(access_token)
{
    token = access_token;
    axiosTransferInstance.defaults.headers.Authorization = 'Bearer ' + access_token;
}

export function getTransferAuthToken()
{
    return token;
}

export default axiosTransferInstance;