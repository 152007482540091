import axiosInstance, { manageReason } from "../actions/axios";
import { emailAddress } from "./config";

export function localizeYesNo(value) {
  return value ? "Si" : "No";
}

export function getAllowedCategories(userInfo, userProfile) {
  if (!userInfo || !userProfile) return [];

  var categories = [userProfile.category];

  for (const [cat, type] of Object.entries(userInfo.acl)) {
    if (type !== "none") categories.push(cat);
  }

  return categories;
}

export async function doAction(action) {
  return await axiosInstance
    .post("/action.php", { request: action })
    .then((response) => {
      return { result: true };
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      return { error: message, exit };
    });
}

export async function getLogs() {
  return await axiosInstance
    .post("/action.php", { request: "getLogs" })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      return { error: message, exit };
    });
}

export async function getSessions() {
  return await axiosInstance
    .post("/action.php", { request: "getSessions" })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      return { error: message, exit };
    });
}

export async function deleteRequest(request, preview) {
  return await axiosInstance
    .post("/action.php", { request, preview })
    .then((response) => {
      return { ...response.data, error: undefined };
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      return { error: message, exit };
    });
}

export async function loadPricelist() {
  return await axiosInstance
    .post("/pricelist.php", { request: "get" })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      return { rules: [], items: [], message, exit };
    });
}

export async function updatePricelist(pricelistobject) {
  return await axiosInstance
    .post("/pricelist.php", { request: "set", pricelistobject })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      return { error: message, exit };
    });
}

export function findDiscByCategory(catkey, categories, disciplines) {
  const discId = categories?.find((cat) => cat.key === catkey).discipline;

  return disciplines?.find((disc) => disc.id === discId);
}

export function getEmailAdmin(discipline) {
  return discipline?.email || emailAddress;
}
