import {
  USERS_DELETE_USER,
  USERS_FAIL_USERDATA,
  USERS_LOADPROFILESPARTIAL_SUCCESS,
  USERS_LOADPROFILES_FAIL,
  USERS_LOADPROFILES_REQUEST,
  USERS_LOADPROFILES_SUCCESS,
  USERS_REQUEST_USERDATA,
  USERS_UPDATE_USERDATA,
} from "../constants/usersConstants";

export const userProfilesReducer = (state = { userProfiles: [] }, action) => {
  switch (action.type) {
    case USERS_LOADPROFILES_REQUEST:
      return { ...state, loading: true };
    case USERS_LOADPROFILESPARTIAL_SUCCESS:
      return { userProfiles: action.payload };
    case USERS_LOADPROFILES_SUCCESS:
      return { loading: false, userProfiles: action.payload };
    case USERS_LOADPROFILES_FAIL:
      return { loading: false, error: action.payload };
    case USERS_REQUEST_USERDATA:
      return {
        ...state,
        loading: true,
        message: undefined,
      };
    case USERS_FAIL_USERDATA: 
      return { ...state, loading: false, message: null, error: action.payload };
    case USERS_UPDATE_USERDATA:
      if (action.payload) {
        let updatedUser = action.payload.user;
        if (updatedUser && state.userProfiles.length > 0) {
          let profiles = [...state.userProfiles];
          const index = profiles.findIndex(
            (user) => user.id === updatedUser.id
          );
          if (index !== -1) profiles[index] = updatedUser;
          return {
            ...state,
            userProfiles: profiles,
            loading: false,
            message: action.payload.message,
          };
        }
      }

      return { ...state, message: action.payload.message };
    case USERS_DELETE_USER:
      let profiles = [...state.userProfiles];
      const index = profiles.findIndex((user) => user.id === action.payload);
      if (index !== -1) profiles.splice(index, 1);
      return { ...state, userProfiles: profiles };
    default:
      return state;
  }
};
